import React, { useState } from 'react'
import * as S from './styles'

export type FieldCheckProps = {
  id?: string
  label?: string
  name?: string
  type?: string
  error?: string
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  initialValue?: boolean
  isChecked?: boolean
  children?: React.ReactNode
  onChange?: (value: boolean) => void
  fieldName?: string
}

const CheckBoxField = ({
  id,
  label,
  name,
  fieldName,
  initialValue = false,
  disabled = false,
  isChecked,
  icon,
  iconPosition,
  error,
  children,
  onChange,
  ...props
}: FieldCheckProps) => {
  const [checked, checkedValue] = useState<boolean>(initialValue)

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.checked
    checkedValue(newValue)

    !!onChange && onChange(newValue)
  }

  return (
    <S.Wrapper checked={checked}>
      <label className="checkbox">
        <span className="checkbox__input">
          <input
            type="checkbox"
            {...props}
            id={id}
            alt={label}
            name={name}
            checked={isChecked ? isChecked : checked}
            placeholder={label}
            onChange={onInputChange}
            disabled={disabled}
          />
          <span className="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </span>
        </span>
      </label>

      {fieldName && <label htmlFor={fieldName}>{fieldName}</label>}
    </S.Wrapper>
  )
}

type PropsDoubleCheckBox = {
  label: string
  value: any
  fieldName?: string
  onChange: (value: any) => void
}

export const DoubleCheckBoxField = ({
  label,
  value,
  onChange,
  fieldName
}: PropsDoubleCheckBox) => {
  return (
    <S.Wrapper checked={value}>
      <label className="checkbox">
        <span className="checkbox__input">
          <input
            type="checkbox"
            checked={value}
            onChange={onChange}
            placeholder={label}
          />
          <span className="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeWidth="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </span>
        </span>
      </label>

      {fieldName && <label htmlFor={fieldName}>{fieldName}</label>}
    </S.Wrapper>
  )
}

export default CheckBoxField
