/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'

import { CounterSampleProps } from '../../types/counterSample'

import { AxiosHttpClient } from '../../resources/http'

export type InfiniteScrollProps = {
  url: string
}

type _value = {
  _value: CounterSampleProps[]
}

export type CounterProps = {
  ca_views: _value
  next_offset: number
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
const InfiniteScroll = ({ url }: InfiniteScrollProps) => {
  const [page, setPage] = useState(1)
  const [data, setData] = useState<CounterSampleProps[]>([])
  const [pageSize, setPageSize] = useState(10)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ajax = async () => {
    try {
      const http = new AxiosHttpClient()

      const response = await http.request<any>(`${url}/${page}`, {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      })

      if (response && response.body) {
        setPageSize
        setData(response!.body!.ca_views || [])
      }
    } catch (error) {
      // setData()
      console.log(error)
      setData([])
    }
  }

  useEffect(() => {
    const main = async () => {
      await ajax()
    }

    main()
  }, [page])

  const handleObserver = (event: any, value: any) => {
    event
    setPage(value)
  }

  return (
    <Stack>
      <Pagination
        count={data.length / pageSize}
        onChange={handleObserver}
        page={page}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  )
}

export default InfiniteScroll
