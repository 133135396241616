/* eslint-disable prettier/prettier */
import React, { Children, cloneElement, useState } from 'react'
import * as materialOutlined from '@styled-icons/material-outlined'
import { useForm } from 'react-hook-form'

// import Form from 'react-bootstrap/Form'
import { Container, Col, Modal } from 'react-bootstrap'

import { Checkbox } from '@mui/material'

import TextFieldNormal from '../InputForms/TextFieldNormal'
import { CounterSampleProps } from 'types/counterSample'
import Spinner from '../Spinner'
import Button from 'components/Button'

import { formatSearchItem, removeDuplicate } from 'utils/tests/formatFilterData'
import { AxiosHttpClient } from '../../resources/http'
import './style.css'

import * as S from './styles'
import { useAgainstSample } from 'store'

type IFilterProps = {
  children?: React.ReactNode
  type?: 'check' | 'TrueOrFalse' | 'year'
  data: CounterSampleProps[]
  prop?: string
}

export type TValues = {
  propName: string
  status: boolean
  value: any[]
}

type _value = {
  _value: TValues[]
}

type Response = {
  value: _value
}

const FilterSearch = ({ children, data, prop }: IFilterProps) => {
  const [show, setShow] = useState(false)
  const [selectAll, setselectAll] = useState(false)
  const [values, setValues] = useState<TValues[]>([])
  const [filtered, setFiltered] = useState<TValues[]>()

  const { register, handleSubmit } = useForm()

  const {
    counterSample,
    getCounter,
    clearThisFilter,
    clearAll,
    removeOneCounter
  }: any = useAgainstSample()

  const handleModal = () => {
    setShow(!show)
    if (data && prop) {
      main()
    }
  }

  const main = async () => {
    const http = new AxiosHttpClient()

    const response = await http.request<Response>(`/estoque/filter/metadata`, {
      body: {},
      method: 'get',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response && response.body) {
      if (response.body.value) {
        const result = response.body.value._value.filter(
          (v) => v.propName === prop
        )

        // if (result.length > 0) console.log(sortFn(result))

        const trueCounters = counterSample.counters.filter(
          (count: any) => count.propName === prop
        )

        let withoutDuplicate = removeDuplicate(result)
        // SE ESTE FILTRO ESTIVER ATIVO
        if (trueCounters.length > 0) {
          // handledTrue(withoutDuplicate, {})

          withoutDuplicate = withoutDuplicate.map((counter) => {
            const counterlower = counter.value[0]
            if (
              counter.value[0] !== null &&
              counter.propName === prop &&
              trueCounters.find((c: any) => c.value.includes(counterlower))
            ) {
              return { ...counter, status: true }
            } else {
              return { ...counter, status: false }
            }
          })

          if (result) {
            setValues(withoutDuplicate)
            setFiltered(withoutDuplicate)
          }
        } else {
          if (result) {
            setValues(withoutDuplicate)
            setFiltered(withoutDuplicate)
          }
        }
      }
    }
  }

  const onSubmit = async () => {
    if (counterSample) {
      getCounter(values, prop)
      setShow(!show)
    }
  }

  const clearThisFilterEvent = () => {
    if (clearThisFilter) {
      clearThisFilter(prop)
      setShow(!show)
    }
  }

  const clearAllFilters = () => {
    if (clearAll) {
      clearAll()
      setShow(!show)
    }
  }

  const selectAllFilters = () => {
    setselectAll(!selectAll)
    const data = values

    const newData = data.map((counter) => {
      return { ...counter, status: !counter.status }
    })

    setValues(removeDuplicate(newData))
    setFiltered(removeDuplicate(newData))
  }

  const renderChildren = () => {
    const childrens = Children.map(children, (child) => {
      return cloneElement(child as React.ReactElement<any>, {
        handleModal: handleModal
      })
    })

    return childrens
  }

  const searchItens = (term: string) => {
    if (term && values)
      setFiltered(values.filter((item) => item.value.includes(term)))

    if (!term) {
      const result: TValues[] = formatSearchItem(data, prop)
      setValues(removeDuplicate(result))
      setFiltered(removeDuplicate(result))
    }
  }

  const handleStatus = (item: any) => {
    const data = values

    if (data.length > 0) {
      const newData = handledTrue(data, item)

      setValues(removeDuplicate(newData))
      setFiltered(removeDuplicate(newData))
    }
    removeOneCounter(prop, item)
  }

  const handledTrue = (data: TValues[], item: any) => {
    const newData = data.map((counter) => {
      const counterlower = counter.value[0]
      if (
        counter.value[0] !== null &&
        counter.propName === prop &&
        counterlower.trim().toLowerCase() === item.value[0].trim().toLowerCase()
      ) {
        return { ...counter, status: !counter.status }
      } else {
        return counter
      }
    })
    return newData
  }

  return (
    <>
      {renderChildren()}
      <S.Wrapper>
        <Modal show={show} onHide={handleModal}>
          <Modal.Header>
            <Col md={{ span: 4, offset: 5 }}>
              <h2>{prop?.toUpperCase()}</h2>
            </Col>
            <i onClick={handleModal}>
              <materialOutlined.Close size="20px" />
            </i>
          </Modal.Header>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Container
              style={{
                height: '400px',
                overflowY: 'auto'
              }}
            >
              <S.ClearFilter>
                <a className="actions" onClick={clearAllFilters}>
                  Limpar Filtros
                </a>
                <a className="actions" onClick={clearThisFilterEvent}>
                  Limpar este Filtro
                </a>
              </S.ClearFilter>

              <div className="form-group">
                <TextFieldNormal onInputChange={(term) => searchItens(term)} />
                <div className="select-all">
                  <Checkbox
                    checked={selectAll}
                    onChange={() => selectAllFilters()}
                  />
                  <a className="actions" onClick={clearThisFilterEvent}>
                    (Selecionar Todos)
                  </a>
                </div>
                <ul className="list">
                  {filtered &&
                  filtered.length > 0 &&
                  filtered[0].value[0] !== undefined ? (
                    filtered.map((item, i) => (
                      <span key={i} className="mb-3">
                        <Checkbox
                          // type="checkbox"
                          {...register(item.value[0]?.toString() || ' ')}
                          id={`${i}-${item.propName}`}
                          // label={item.value[0].toString()}
                          checked={item.status}
                          onChange={() => handleStatus(item)}
                        />
                        <i>{item.value[0]}</i>
                      </span>
                    ))
                  ) : (
                    <Spinner />
                  )}
                </ul>
              </div>
            </Container>
            <Modal.Footer>
              <Button color="primary">Filtrar</Button>
            </Modal.Footer>
          </form>
        </Modal>
      </S.Wrapper>
    </>
  )
}

export default FilterSearch
