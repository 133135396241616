/* eslint-disable prettier/prettier */
// @typescript-eslint/no-unused-vars-experimental
import { useState } from 'react'
import * as S from './styles'

export type FieldProps = {
  id?: string
  label?: string
  name?: string
  type?: string
  error?: string
  TextArea?: boolean
  size?: 'normal' | 'full'
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  initialValue?: string
  children?: React.ReactNode
  onChange?: (value: string) => void
  fieldName?: string
  callbackLoader?: any
  clearInput?: () => void
  style?: any
}

const TextField = ({
  id,
  label,
  name,
  TextArea = false,
  fieldName,
  initialValue = '',
  disabled = false,
  icon,
  iconPosition = 'right', // eslint-disable-line
  error,
  size,
  children,
  clearInput,
  style,
  callbackLoader,
  onChange,
  ...props
}: FieldProps) => {
  const [value, setValue] = useState(initialValue)
  const onInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e && e.currentTarget) {
      const newValue = e.currentTarget.value
      setValue(newValue)

      !!onChange && onChange(newValue)
    }
  }

  return (
    <S.Wrapper TextArea={TextArea} size={size}>
      <div className="header">
        {fieldName && <label htmlFor={fieldName}>{fieldName}</label>}
        {callbackLoader && callbackLoader}
      </div>
      {!TextArea && (
        <input
          {...props}
          id={id}
          style={style}
          alt={label}
          name={name}
          placeholder={label}
          defaultValue={initialValue}
          onChange={onInputChange}
          value={value}
          disabled={disabled}
        />
      )}

      {TextArea && (
        <>
          <textarea
            id={id}
            placeholder={label}
            name={name}
            style={style}
            onChange={onInputChange}
            disabled={disabled}
            value={value || initialValue}
            {...props}
          >
            {children}
          </textarea>
        </>
      )}

      {!!icon && <S.Icon iconPosition={iconPosition}>{icon}</S.Icon>}

      {error && <span style={{ color: '#f00' }}>{error}</span>}
    </S.Wrapper>
  )
}

export default TextField
