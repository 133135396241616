import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    input {
      width: 100%;
      margin-bottom: 15px;
      padding: 6px;
      border-radius: 4px;
      font-size: 15px;
      color: #444;
      border-color: hsl(0, 0%, 80%);
      border-style: solid;
      border-width: 1px;
      transition: border-color 0.2s;

      &:focus {
        border-color: ${theme.colors.primary};
      }
    }
  `}
`
