/* eslint-disable prettier/prettier */
import { Children, cloneElement, useEffect, useState } from 'react'
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import ReactLoading from 'react-loading'
import { toast } from 'react-toastify'
import * as S from './styles'

// HTTP
import { AxiosHttpClient } from 'resources/http'

import Tabs, { Tab } from 'components/Tabs'
// import Modal, { refProps } from 'components/Modal'
import FormEntrace from 'components/Forms/FormEntrance'
import FormExit from 'components/Forms/FormExit'
import FormModeling from 'components/Forms/FormModeling'
import FormSeal from 'components/Forms/FormSeal'
import Spinner from 'components/Spinner'
import { IdentifierProps } from 'types/stock'
import useModalData from 'hooks/useModalData'
import { StatusLogScreen, StockScreen } from '../screenStock'
import { CloseButton, Col } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'

import TextField from '../../components/InputForms/TextField'
import debounce from 'lodash.debounce'
import Button from 'components/Button'

interface IModalAS {
  children: any
  show?: boolean
}

const OFFSET_DEFAULT = 2
const TIME_TO_RECORD_FOLLOW_UP = 100

export type productProps = {
  id_estoque_ca: string
  provider: string
  date: string
  justification: string
  movement: string
  type: string
  id_produto: number
  status: string
  delivery_note: string
  note_quality: string
  pilot: boolean
  rework: number
  destination: string
  kit: boolean
  risk: boolean
  note: string
  counter_sample: boolean
  reason: string
  origin?: string
}

const ModalCounterSample = ({ children }: IModalAS) => {
  const [show, setShow] = useState(false)

  const [identifier, setIdentifier] = useState<IdentifierProps>({
    idUser: 1,
    idProduct: '',
    ref: '',
    note: '',
    idEstoqueCa: ''
  })

  const { clearAll } = useModalData(
    OFFSET_DEFAULT,
    identifier.ref,
    identifier.idUser
  )

  const showModal = () => {
    setShow(!show)
    clearAll()
  }

  const clearState = () => {
    if (!show) {
      clearAll()
    }
  }

  const renderChildren = () => {
    const childrens = Children.map(children, (child) => {
      return cloneElement(child as React.ReactElement<any>, {
        handleModal: (options: IdentifierProps) => {
          setIdentifier({
            idUser: options.idUser,
            idProduct: options.idProduct,
            note: options.note,
            ref: options.ref,
            idEstoqueCa: options.idEstoqueCa
          })
          showModal()
        }
      })
    })

    return childrens
  }

  const debouncedChange = debounce(
    async (value: string) => await handleFollow(value),
    TIME_TO_RECORD_FOLLOW_UP
  )

  const handleFollow = async (text: string) => {
    const ajax = async () => {
      const http = new AxiosHttpClient()

      const response = await http.request<any>(`estoque/historic/field`, {
        body: {
          text: text,
          idProduct: identifier.idProduct
        },
        method: 'post',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      })

      if (response && response.body) {
        if (response.statusCode === 200) {
          toast.success('Follow up registrado com sucesso !')
        } else {
          toast.error('Error ao gravar o follow up.')
        }
      }
    }

    await ajax()
  }

  return (
    <>
      {renderChildren()}
      <Modal show={show} onHide={showModal} size="lg">
        <S.Wrapper>
          <Modal.Header>
            <span className="title">{`${identifier.ref}-${identifier.note}`}</span>
            <CloseButton
              onClick={showModal}
              style={{ border: 0, background: '#fff' }}
            />
          </Modal.Header>
          <Modal.Body style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
            <Tabs>
              <Tab label="historico">
                <HistoricContainer
                  product={identifier.ref || undefined}
                  user={identifier.idUser || undefined}
                  IsOpen={show}
                  clearState={clearState}
                  onPress={debouncedChange}
                  close={() => showModal()}
                />
              </Tab>
              <Tab label="entrada">
                <FormEntrace
                  identifier={identifier}
                  close={() => {
                    showModal()
                  }}
                />
              </Tab>
              <Tab label="saída">
                <FormExit
                  identifier={identifier}
                  close={() => {
                    showModal()
                  }}
                />
              </Tab>
              <Tab label="modelagem">
                <FormModeling
                  identifier={identifier}
                  close={() => {
                    showModal()
                  }}
                />
              </Tab>
              <Tab label="estoque">
                <StockScreen identifier={identifier} />
              </Tab>
              <Tab label="lacre">
                <FormSeal
                  identifier={identifier}
                  close={() => {
                    showModal()
                  }}
                />
              </Tab>
            </Tabs>
          </Modal.Body>
        </S.Wrapper>
      </Modal>
    </>
  )
}

export default ModalCounterSample

type HistoricContainerProps = {
  onPress: (value: any) => void
  clearState: () => void
  product?: string
  user?: number
  IsOpen: boolean
  close: () => void
}

export const HistoricContainer = ({
  onPress,
  clearState,
  product,
  user,
  close
}: HistoricContainerProps) => {
  const [followUp, setFollowUp] = useState<string | null>('')
  const [statusLogs, setStatusLogs] = useState([])
  const { historic, getHistoric, clearHistoric } = useModalData(
    OFFSET_DEFAULT,
    product,
    user
  )

  useEffect(() => {
    ;(async function () {
      await getHistoric()
    })()
  }, [getHistoric])

  useEffect(() => {
    const req = new AxiosHttpClient()

    const getStatusLog = async () => {
      const resp = await req.request<any>(`/estoque/status_log/${product}`, {
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      })

      if (resp.body) {
        setStatusLogs(resp.body.ca_views._value?.result)
        const decoderfollowup = decodeURIComponent(
          resp.body.ca_views?._value?.followUp[0].FOLLOW_UP
        )
        setFollowUp(decoderfollowup)
      }
    }

    ;(async () => {
      await getStatusLog()
    })()

    return () => {
      clearState(), setFollowUp('')
    }
  }, [clearState, product])

  return historic ? (
    historic.length > 0 ? (
      <>
        <StatusLogScreen data={statusLogs} hasComment={false} />
        <Col md={{ span: 10, offset: 1 }}>
          <TextField
            onChange={(value) => {
              setFollowUp(value)
            }}
            TextArea
            fieldName="follow up"
            initialValue={followUp || undefined}
            size={'full'}
            callbackLoader={followUp === undefined ? <> </> : null}
          />
        </Col>

        <Col md={{ span: 6, offset: 6 }}></Col>
        <Col md={{ span: 12, offset: 9 }}>
          <Button
            onClick={() => {
              onPress(followUp && encodeURIComponent(followUp)),
                close(),
                clearHistoric()
            }}
            color="primary"
          >
            Salvar
          </Button>
        </Col>
      </>
    ) : (
      <>
        <StatusLogScreen
          data={statusLogs}
          onPress={setFollowUp}
          state={followUp}
          hasComment={true}
        />
        <Col md={{ span: 12, offset: 9 }}>
          <Button
            onClick={() => {
              onPress(followUp && encodeURIComponent(followUp)),
                close(),
                clearHistoric()
            }}
            color="primary"
          >
            Salvar
          </Button>
        </Col>
      </>
    )
  ) : (
    <Spinner />
  )
}
