/* eslint-disable prettier/prettier */
export type CounterSampleProps = {
  called: any
  BpColor: string
  bpColor: string
  DataUltimoStatusProva: string
  DueDate: string
  MotivoUltimoStatusProva: number
  anoLoja: string
  canal: string
  colecao: string
  dataInsercao: string
  dataLacre: string
  data_alvo_ca: string
  idProduct: string
  descProduto?: string
  desenvolvimento?: string
  entregaInicial: string
  estilista?: string
  fornecedor?: string
  grupo?: string
  id: string
  linha?: string
  marca?: string
  mes: number
  dataProg: string
  idEstoqueCa?: string
  modelista?: string
  previsaoCA?: string
  dataEntracePilot?: string
  dataEntraceRisk?: string
  dataEntraceKit?: string
  dataEntraceCounterSample?: string
  dateEntrancePilotStock?: string
  dateEntranceRiskStock?: string
  dateEntranceKitStock?: string
  dateEntranceCounterSampleStock?: string
  produto: string
  programacao: string
  statusCA: string
  pecaNumero: number
  tempStatus: string
  talQual: string
  dataLacreStyle: string
  ultimoStatusProva?: string
  dataSaidaFornecedor: string
  dateProviderExit: string
  providerExit: string
  provider: string
  dataLacreEstilo: string
  dataStatusCaPa: string
  colecaoCompleta: string
  dataAprovCa: string
  dataLacreCA: string
  dateEstimatedDeliveryCa: string
  dateDeliveryCa: string
}

export type _value = {
  _value: CounterSampleProps[]
}

export type CounterProps = {
  ca_views: CounterSampleProps[]
  next_offset: number
}

export const statusList = [
  'Aguardando piloto lacrada',
  'Em Prova',
  'No Fornecedor',
  'Enviar Piloto, Kit e Risco',
  'Falta Kit',
  'Enviar Piloto e Kit',
  'Falta Kit e Risco',
  'Pendente Lacre',
  'Enviar Kit e Risco',
  'Enviar Piloto e Risco',
  'Enviar Piloto',
  'Falta Piloto, Kit e Risco',
  'Enviar Risco',
  'Enviar Kit',
  'Aprovado com peça na frente',
  'Verificando Modelagem',
  'A Aprovar com peça na frente',
  'Aprovado'
]
