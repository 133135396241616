import styled, { css, DefaultTheme } from 'styled-components'

type LabelProps = {
  label: string
  target?: number
  theme: DefaultTheme
}

const wrapperModifiers = {
  active: () => css`
    background-color: #1763d5;
    color: #fafafa;
    border: 1px solid #eaeaea;
    box-shadow: none;
  `
}

export const Label = styled.li.attrs((props: LabelProps) => ({
  label: props.label,
  target: props.target
}))`
  ${({ theme, target }) => css`
    line-height: 2.5rem;
    text-align: center;
    align-items: stretch;
    flex-grow: 1;
    border-right: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    box-shadow: inset 0px 2px 4px #ccc;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    background-color: #f1f2f6;
    padding-top: 10px;
    transition: transform 0.2s ease-in, opacity ${theme.transition.default};

    ${Number(target) ? wrapperModifiers.active() : null}
  `}
`

export const Tab = styled.div<LabelProps>`
  margin: 0 auto;
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    max-width: 100%;
    height: 400px;
    margin: 0 auto;

    ul {
      width: 100%;
      height: 50px;
      display: flex;
      list-style: none;
      position: absolute;
      left: 0px;
      top: 0;
      padding: 0;
    }

    .content {
      width: 100%;
      position: relative;
      top: ${theme.spacings.xlarge};
    }
  `}
`
