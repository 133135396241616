/* eslint-disable prettier/prettier */
import { useEffect, useState, useMemo } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
// GRID
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// COMPONENTS
import { GroupTypeBase } from 'react-select'
import SelectField, { ChangeSelectProps } from '../../SelectField'
import CheckBoxField, { DoubleCheckBoxField } from '../../InputForms/CheckField'
import TextField from '../../InputForms/TextField'
import Button from '../../Button'

import { useFetch } from '../../../lib/hooks/useFetch'
import { AxiosHttpClient } from '../../../resources/http'
import { IdentifierProps } from '../../../types/stock'
import Spinner from '../../Spinner'
import debounce from 'lodash.debounce'

type TypeProps = {
  motivo: string
  id_motivo_reprovacao: number
}

type Options =
  | (ChangeSelectProps | GroupTypeBase<ChangeSelectProps>)[]
  | (undefined & {
      value: string | number
      label: string | number
    })

type ModelingSend = {
  called: string
  rework: boolean
  typeProblem: string
  note: string
  justification: string
  idProduct: string
  idUser: number | undefined
}

export type FormModelingProps = {
  identifier: IdentifierProps
  children?: React.ReactChild
  close: () => void
}

const FormModeling = ({ identifier, close }: FormModelingProps) => {
  const { register, handleSubmit } = useForm()
  const [options, setOptions] = useState<Options>([{ value: ' ', label: ' ' }])
  const [activeItemName, setActiveItemName] = useState<any[]>()
  const [checked, setChecked] = useState<number>(0)

  const [values, setValues] = useState({
    called: '',
    rework: false,
    typeProblem: '',
    note: '',
    justification: '',
    kit: false,
    risk: false,
    pilot: false,
    idProduct: identifier.idProduct || '',
    idUser: identifier.idUser
  })

  useEffect(() => {
    const main = async () => {
      await ajax()
    }
    setOptions
    main()
  }, [])

  const ajax = async () => {
    const http = new AxiosHttpClient()

    const response = await http.request<any[]>(
      `/product/${identifier.idProduct}/${'modelagem'}`,
      {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      }
    )

    if (response && response.body) {
      setActiveItemName(response.body)
      if (
        response.body &&
        response.body.length > 0 &&
        response.body[0].rework &&
        response.body[0].rework === 1
      ) {
        setChecked(1)
      }

      if (response && response.body) {
        const newActive = {
          ...values,
          typeProblem:
            response.body[0] && response.body[0].type_problem
              ? response.body[0].type_problem
              : null,
          rework:
            response.body[0] && response.body[0].rework === 1 ? true : false,
          justification:
            response.body[0] && response.body[0].justification
              ? response.body[0].justification
              : null,
          called:
            response.body[0] && response.body[0].called
              ? response.body[0].called
              : null,
          risk: response.body[0] && response.body[0].risk === 1 ? true : false,
          pilot:
            response.body[0] && response.body[0].pilot === 1 ? true : false,
          kit: response.body[0] && response.body[0].kit === 1 ? true : false,
          note: response.body[0] && response.body[0].note
        }

        setValues(newActive)
      }
    }
  }

  const changeText = (value: string) => {
    const field = 'justification'
    if (value) {
      setValues((s) => ({ ...s, [field]: value }))
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce(changeText, 300), [])

  const { data } = useFetch<TypeProps[]>({
    url: `/estoque/modelagem/type_problem`,
    method: 'GET',
    headers: {
      authorization: localStorage.getItem('userToken')
    }
  })

  const onSubmit = () => {
    const {
      called,
      typeProblem,
      note,
      justification,
      idProduct,
      kit,
      pilot,
      risk,
      idUser
    } = values

    const request = {
      called: called
        ? called
        : activeItemName && activeItemName[0] && activeItemName[0].called
        ? activeItemName[0].called
        : null,
      rework:
        checked === 1
          ? true
          : activeItemName && activeItemName[0] && activeItemName[0].rework
          ? true
          : false,
      typeProblem: typeProblem
        ? typeProblem
        : activeItemName && activeItemName[0].typeProblem
        ? activeItemName[0].typeProblem
        : null,
      justification: justification
        ? justification
        : activeItemName && activeItemName[0] && activeItemName[0].justification
        ? activeItemName[0].justification
        : null,
      note: note
        ? note
        : activeItemName && activeItemName[0] && activeItemName[0].note
        ? activeItemName[0].note
        : null,
      idProduct: idProduct,
      idUser: idUser,
      code: identifier.ref
        ? identifier.ref
        : activeItemName && activeItemName[0] && activeItemName[0].code
        ? activeItemName[0].code
        : identifier.ref,
      options: {
        kit: kit ? kit : false,
        pilot: pilot ? pilot : false,
        risk: risk ? risk : false
      }
    }

    handlModeling(request)
  }

  const handleInput = (field: string, value: any) => {
    if (field === 'rework') {
      if (value !== checked && value === true) {
        setChecked(1)
      }

      if (value !== checked && value === false) {
        setChecked(0)
      }
    } else {
      setValues((s) => ({ ...s, [field]: value }))
    }
  }

  const handlModeling = async (data: ModelingSend) => {
    const http = new AxiosHttpClient()

    const response = await http.request('/estoque/modelagem', {
      body: data,
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response && response.statusCode === 200) {
      toast.success('Modelagem adicionada com sucesso !')
      close()
    }

    if (response && response.statusCode === (500 || 404)) {
      toast.error('Um problema inesperado ocorreu favor recarregar a página!')
      close()
    }
    close()
  }

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((option: any) => {
          return {
            value: option.motivo,
            label: option.motivo
          }
        })
      )
    }
  }, [data])

  return (
    <>
      <S.Wrapper>
        <Container fluid>
          {activeItemName && activeItemName.length >= 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={{ span: 6 }}>
                  <TextField
                    {...register('called')}
                    onChange={(value) => handleInput('called', value)}
                    fieldName="chamado"
                    initialValue={
                      activeItemName &&
                      activeItemName[0] &&
                      activeItemName[0].called
                        ? activeItemName[0].called
                        : null
                    }
                  />
                  <SelectField
                    {...register('typeProblem')}
                    fieldName="tipo de problema"
                    onChange={(value) => handleInput('typeProblem', value)}
                    initialValue={{
                      value:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].type_problem
                          : null,
                      label:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].type_problem
                          : null
                    }}
                    options={options}
                  />
                  <Col>
                    <div className="checkbox_container">
                      <CheckBoxField
                        {...register('pilot')}
                        onChange={(value) => handleInput('pilot', value)}
                        fieldName="modelagem"
                        initialValue={
                          activeItemName &&
                          activeItemName[0] &&
                          activeItemName[0].pilot === 1
                            ? true
                            : false
                        }
                      />
                      <CheckBoxField
                        {...register('kit')}
                        onChange={(value) => handleInput('kit', value)}
                        fieldName="ficha técnica"
                        initialValue={
                          activeItemName &&
                          activeItemName[0] &&
                          activeItemName[0].kit === 1
                            ? true
                            : false
                        }
                      />
                      <CheckBoxField
                        {...register('risk')}
                        onChange={(value) => handleInput('risk', value)}
                        fieldName="cad"
                        initialValue={
                          activeItemName &&
                          activeItemName[0] &&
                          activeItemName[0].risk === 1
                            ? true
                            : false
                        }
                      />
                    </div>
                  </Col>
                  <TextField
                    {...register('note')}
                    TextArea
                    onChange={(value) => handleInput('note', value)}
                    fieldName="observacao"
                    initialValue={activeItemName[0] && activeItemName[0].note}
                  />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <S.TitleForm>houve retrabalho</S.TitleForm>
                  <div className="checkbox_container checkbox_container--modify">
                    <DoubleCheckBoxField
                      {...register('yes')}
                      label="yes"
                      value={checked === 1 ? true : false}
                      onChange={(value: any) =>
                        handleInput('rework', value.currentTarget.checked)
                      }
                      fieldName="sim"
                    />
                    <DoubleCheckBoxField
                      {...register('not')}
                      label="not"
                      value={checked === 1 ? false : true}
                      onChange={(value: any) =>
                        handleInput('rework', value.currentTarget.checked)
                      }
                      fieldName="não"
                    />
                  </div>
                  <TextField
                    {...register('justification')}
                    fieldName="justificativa"
                    onChange={debouncedChangeHandler}
                    TextArea
                    initialValue={
                      activeItemName[0] && activeItemName[0].justification
                    }
                  />
                </Col>
              </Row>
              <div className="button-container">
                <Button color="primary">Salvar</Button>
              </div>
            </form>
          ) : (
            <Spinner />
          )}
        </Container>
      </S.Wrapper>
    </>
  )
}

export default FormModeling
