/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import { useFetch } from 'lib/hooks/useFetch'
import { Accordion, Row, Col, Card } from 'react-bootstrap'

import * as S from './style'
import { CounterSampleProps, statusList } from 'types/counterSample'

type ResumeProps = {
  title: string
  resumeData?: CounterSampleProps[]
}

type _value = {
  _value: any[]
}

type values = {
  value: _value
}

const Resume = ({ title, resumeData }: ResumeProps) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [resume, setResume] = useState<Object[]>([])
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [filteredResume, setFilteredResume] = useState<Object[]>([])
  const [open, setOpen] = useState('')

  const { data } = useFetch<values>(
    {
      url: `estoque/resume`,
      method: 'GET',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    },
    {
      revalidateOnFocus: true
    }
  )

  useEffect(() => {
    if (data && data.value) {
      const { _value } = data.value
      setResume(_value)
    }
  }, [data])

  const buildSummaryStatistics = (stocks: CounterSampleProps[], cb: any) => {
    let counter = 0
    let counterByStatus:
      | { status: string; counter: number }[]
      | { status: string; counter: number }[] = []
    const quantityByStatus = []

    for (const stock of stocks) {
      const status = stock.statusCA

      if (
        status &&
        cb &&
        status.toLocaleLowerCase() === cb.toLocaleLowerCase()
      ) {
        const somar = 1

        counter += somar
        counterByStatus.push({ status: status, counter: somar })
      }
    }

    if (counterByStatus.length > 0) {
      for (const summary of counterByStatus) {
        const result: any = counterByStatus.filter(
          (sbn: { status: string }) =>
            sbn.status.toLocaleLowerCase() ===
            summary.status.toLocaleLowerCase()
        )

        quantityByStatus.push({
          status: result[0].status,
          counter: result.length
        })
        counterByStatus = quantityByStatus
      }
    }
    const percentage = Math.round((counter / stocks.length) * 100) || 0

    return { counter, percentage, counterByStatus }
  }

  useEffect(() => {
    const summary: any[] = []

    statusList.forEach((item) => {
      if (resumeData && resumeData.length > 0) {
        const respSum = buildSummaryStatistics(resumeData, item)

        summary.push({ [item]: respSum, total: resumeData.length })
      }
    })

    setResume(summary)

    if (resumeData && resumeData.length === 0) {
      if (data) {
        setResume(data?.value?._value || [])
      }
    }

    setFilteredResume(summary)
  }, [data, resumeData])

  return (
    <S.Wrapper>
      <Accordion defaultActiveKey={open}>
        <Card style={{ width: '340px' }}>
          <Accordion.Toggle
            style={{
              background: '#1763D5',
              color: '#fff',
              fontSize: '12px',
              fontWeight: 600
            }}
            as={Card.Header}
            eventKey={open}
            onClick={() => setOpen('0')}
          >
            {title}
          </Accordion.Toggle>

          <Accordion.Collapse eventKey={open}>
            <Card.Body
              style={{
                overflowY: 'scroll',
                maxHeight: '200px'
              }}
            >
              <Row className="resume-header">
                <Col xs={{ span: 6 }}>status</Col>
                <Col xs={{ span: 3 }}># OPS</Col>
                <Col xs={{ span: 3 }}>total</Col>
              </Row>
              {filteredResume.length === 0 && resume
                ? resume.map((item, i) => (
                    <Row key={i} className="resume-sintese">
                      <Col xs={{ span: 6 }} style={{ color: '#000' }}>
                        {Object.keys(item)[0]}
                      </Col>
                      <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                        {Object.values(item)[0].counter}
                      </Col>
                      <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                        {Object.values(item)[0].percentage}%
                      </Col>
                    </Row>
                  ))
                : null}

              {filteredResume && filteredResume.length > 0
                ? filteredResume.map((item, i) => (
                    <Row key={i} className="resume-sintese">
                      <Col xs={{ span: 6 }} style={{ color: '#000' }}>
                        {Object.keys(item)[0]}
                      </Col>
                      <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                        {Object.values(item)[0].counter}
                      </Col>
                      <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                        {Object.values(item)[0].percentage}%
                      </Col>
                    </Row>
                  ))
                : null}
              <Row className="resume-total">
                <Col xs={{ span: 6 }} style={{ color: '#000' }}>
                  total
                </Col>
                <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                  {filteredResume.length === 0 && resume && resume[0]
                    ? Object.values(resume[0])[1]
                    : null}

                  {filteredResume &&
                  filteredResume[0] &&
                  filteredResume.length > 0
                    ? Object.values(filteredResume[0])[1]
                    : null}
                </Col>
                <Col xs={{ span: 3 }} style={{ color: '#000' }}>
                  100%
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </S.Wrapper>
  )
}

export default Resume
