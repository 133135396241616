import { useState } from 'react'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const PLM_URL_MENU = process.env.REACT_APP_URL_MENU

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%'
    },
    navbarStylish: {
      boxShadow: 'none !important',
      backgroundColor: '#FAFAFA !important',
      borderBottom: `1px solid #e7e7e7`,
      height: '60px',
      color: '#777777 !important',
      MuiAppBar: {
        boxShadow: 'none !important'
      }
    },
    logoSoma: {
      height: '52px',
      width: '150px',
      padding: '10px 10px',
      fontSize: `${theme.typography.subtitle1}`
    },
    navLink: {
      textDecoration: 'none',
      color: 'inherit'
    },
    gridLink: {
      cursor: 'pointer',
      padding: '6px 8px'
    },
    gridRight: {
      right: '0'
    }
  })
)

const Navbar = () => {
  const classes = useStyles()
  // if (typeof window !== 'undefined') {
  //   let token: any
  //   token = localStorage.getItem('userInfo') || ''
  // }

  const [state, setState] = useState({
    anchorStyle: null,
    anchorCreation: null,
    anchorProduction: null,
    anchorHunt: null,
    anchorAdmin: null,
    anchorUser: null,
    anchorConfiguration: null,
    usuario: {
      admin: true,
      id_grupo: null,
      areas: [],
      id_estilista: null,
      login: null
    }
  })

  const handleClick = (e: any, anchorEl: string) => {
    if (anchorEl === 'anchorStyle')
      setState((s) => ({ ...s, anchorStyle: e.currentTarget }))
    if (anchorEl === 'anchorCreation')
      setState((s) => ({ ...s, anchorCreation: e.currentTarget }))
    if (anchorEl === 'anchorProduction')
      setState((s) => ({ ...s, anchorProduction: e.currentTarget }))
    if (anchorEl === 'anchorHunt')
      setState((s) => ({ ...s, anchorHunt: e.currentTarget }))
    if (anchorEl === 'anchorAdmin')
      setState((s) => ({ ...s, anchorAdmin: e.currentTarget }))
    if (anchorEl === 'anchorUser')
      setState((s) => ({ ...s, anchorUser: e.currentTarget }))
    if (anchorEl === 'anchorConfiguration')
      setState((s) => ({ ...s, anchorConfiguration: e.currentTarget }))
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  const handleClose = (e?: any) => {
    setState((s) => ({
      ...s,
      anchorStyle: null,
      anchorCreation: null,
      anchorProduction: null,
      anchorHunt: null,
      anchorAdmin: null,
      anchorUser: null,
      anchorConfiguration: null
    }))
  }

  // const logOut = () => {
  //   window.location.href = 'http://plm.somalabs.com.br/'
  // }

  const Administracao = () => {
    const { anchorAdmin, usuario } = state

    if (usuario.admin) {
      return (
        <Grid item>
          <Grid
            item
            container
            onClick={(e) => handleClick(e, 'anchorAdmin')}
            className={classes.gridLink}
          >
            <Typography variant="subtitle1" color="inherit">
              Administração
            </Typography>
            <ArrowDropDown />
          </Grid>
          <Menu
            anchorEl={anchorAdmin}
            open={Boolean(anchorAdmin)}
            onClose={(e) => handleClose(e)}
          >
            <MenuItem>
              <a
                href={PLM_URL_MENU + '/admin/grupos'}
                className={classes.navLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Grupos
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href={PLM_URL_MENU + '/admin/opcoes'}
                className={classes.navLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Opções
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href={PLM_URL_MENU + '/admin/usuarios'}
                className={classes.navLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Usuários
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/assistentes"
                className={classes.navLink}
                rel="noopener noreferrer"
              >
                Assistentes de Estilo
              </a>
            </MenuItem>
            <MenuItem>
              <a
                href="/assistentesModelista"
                className={classes.navLink}
                rel="noopener noreferrer"
              >
                Assistentes de Modelista
              </a>
            </MenuItem>
          </Menu>
        </Grid>
      )
    } else {
      return null
    }
  }

  const Configuracao = () => {
    const { anchorConfiguration, usuario } = state

    if (
      usuario.admin ||
      HasPermission('Quadradinhos,Configurações,Modelagens,Encaixes,Alocação')
    ) {
      return (
        <Grid item>
          <Grid
            item
            container
            onClick={(e) => handleClick(e, 'anchorConfiguration')}
            className={classes.gridLink}
          >
            <Typography variant="subtitle1" color="inherit">
              Configurações
            </Typography>
            <ArrowDropDown />
          </Grid>
          <Menu
            anchorEl={anchorConfiguration}
            open={Boolean(anchorConfiguration)}
            onClose={(e) => handleClose(e)}
          >
            {HasPermission('Quadradinhos') || HasPermission('Configurações') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/configuracoes/estilo'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Estilo
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Configurações') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/configuracoes/geral'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Geral
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Modelagens') || HasPermission('Configurações') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/configuracoes/modelagens'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Modelagens
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Encaixes') || HasPermission('Configurações') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/configuracoes/encaixes'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Encaixes
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Alocação') || HasPermission('Configurações') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/configuracoes/pcp'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PCP
                </a>
              </MenuItem>
            ) : null}
          </Menu>
        </Grid>
      )
    } else {
      return null
    }
  }

  const HasPermission = (valor?: any) => {
    const { usuario } = state
    const areas = usuario.areas

    if (usuario.admin) {
      return true
    }
    for (const key in areas) {
      if (valor.includes(key)) return true
    }

    return false
  }

  const Estilo = () => {
    const { anchorStyle, usuario } = state

    if (
      usuario.admin ||
      usuario.id_estilista ||
      HasPermission('Quadradinhos,Estampas,Otb')
    ) {
      return (
        <Grid item>
          <Grid
            item
            container
            onClick={(e) => handleClick(e, 'anchorStyle')}
            className={classes.gridLink}
          >
            <Typography variant="subtitle1" color="inherit">
              Estilo
            </Typography>
            <ArrowDropDown />
          </Grid>
          <Menu
            anchorEl={anchorStyle}
            open={Boolean(anchorStyle)}
            onClose={(e) => handleClose(e)}
          >
            {HasPermission('Otb') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/otb'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  OTB
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Quadradinhos') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/quadradinhos'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Quadradinhos
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Estampas') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/estampas'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Estampas
                </a>
              </MenuItem>
            ) : null}
            {HasPermission('Quadradinhos') ? (
              <MenuItem>
                <a
                  href={PLM_URL_MENU + '/painel_produto'}
                  className={classes.navLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Painel de Produto
                </a>
              </MenuItem>
            ) : null}
          </Menu>
        </Grid>
      )
    } else {
      return null
    }
  }

  const { anchorCreation, anchorProduction, anchorHunt, usuario } = state

  return (
    <>
      {classes && (
        <div className={classes.root}>
          <AppBar position="static" className={classes.navbarStylish}>
            <Toolbar
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Grid item container alignItems={'center'} sm={8}>
                <Grid item>
                  <img
                    src={'https://www.somalabs.com.br/assets/img/logo_soma.png'}
                    className={classes.logoSoma}
                    alt={'https://www.somalabs.com.br/assets/img/logo_soma.png'}
                  />
                </Grid>
                {Estilo()}
                {usuario.admin ||
                HasPermission(
                  'Pilotagem - Pedido,Pilotagem - Compra,Pilotagem - Estoque,Pilotagem - Almoxarifado,Modelagens'
                ) ? (
                  <Grid item>
                    <Grid
                      item
                      container
                      onClick={(e) => handleClick(e, 'anchorCreation')}
                      className={classes.gridLink}
                    >
                      <Typography variant="subtitle1" color="inherit">
                        Criação
                      </Typography>
                      <ArrowDropDown />
                    </Grid>
                    <Menu
                      anchorEl={anchorCreation}
                      open={Boolean(anchorCreation)}
                      onClose={() => handleClose()}
                    >
                      {HasPermission('Pilotagem - Pedido') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/pedido_pilotagem'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pedidos de Pilotagem
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Pilotagem - Compra') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/compra_pilotagem'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Compra de Pilotagem
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Pilotagem - Estoque') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/estoque_pilotagem'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Estoque de Pilotagem
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Pilotagem - Almoxarifado') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/almox_pilotagem'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Consumo de Pilotagem
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Modelagens') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/modelagens'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Modelagens
                          </a>
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </Grid>
                ) : null}
                {usuario.admin ||
                HasPermission(
                  'Simulados,Ampliação,Ficha Técnica,Alocação,Contra Amostras,Amostras,Encaixes,FollowUp'
                ) ||
                usuario.id_grupo === 32 ? (
                  <Grid item>
                    <Grid
                      item
                      container
                      onClick={(e) => handleClick(e, 'anchorProduction')}
                      className={classes.gridLink}
                    >
                      <Typography variant="subtitle1" color="inherit">
                        Produção
                      </Typography>
                      <ArrowDropDown />
                    </Grid>
                    <Menu
                      anchorEl={anchorProduction}
                      open={Boolean(anchorProduction)}
                      onClose={(e) => handleClose(e)}
                    >
                      {HasPermission('Simulados') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/simulados'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            1º Gasto
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Ampliação') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/ampliacao'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ampliação
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Ficha Técnica') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/ft'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ficha Técnica
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Alocação') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/alocacao'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Alocação
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Contra Amostras') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/contra_amostras'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contra Amostras
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Amostras') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/amostras'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Amostras Tecido
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Encaixes') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/encaixes'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Encaixes
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('SEOE') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/seoe'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            S&OE
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('FollowUp') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/follow_up'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Controle Produção
                          </a>
                        </MenuItem>
                      ) : null}
                      {HasPermission('Ranking Fornecedores') ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/ranking_fornecedores'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Ranking Fornecedores
                          </a>
                        </MenuItem>
                      ) : null}
                      {usuario.admin || usuario.id_grupo === 32 ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/compliance'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Compliance
                          </a>
                        </MenuItem>
                      ) : null}
                      <MenuItem>
                        <a
                          href="/#"
                          className={classes.navLink}
                          rel="noopener noreferrer"
                        >
                          Confirmação Materiais
                        </a>
                      </MenuItem>
                    </Menu>
                  </Grid>
                ) : null}
                <Grid item>
                  <Grid item container className={classes.gridLink}>
                    <a
                      href={
                        'https://plm-colaborativo.somalabs.com.br/divergencias'
                      }
                      className={classes.navLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography
                        variant="subtitle1"
                        color="inherit"
                        style={{ margin: 'auto' }}
                      >
                        Divergências
                      </Typography>
                    </a>
                  </Grid>
                </Grid>
                {usuario.admin ? null : (
                  <>
                    <Grid item>
                      <Grid item container className={classes.gridLink}>
                        <a
                          href={'/assistentes'}
                          className={classes.navLink}
                          rel="noopener noreferrer"
                        >
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            style={{ margin: 'auto' }}
                          >
                            Assistentes de Estilo
                          </Typography>
                        </a>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid item container className={classes.gridLink}>
                        <a
                          href={'/assistentesModelista'}
                          className={classes.navLink}
                          rel="noopener noreferrer"
                        >
                          <Typography
                            variant="subtitle1"
                            color="inherit"
                            style={{ margin: 'auto' }}
                          >
                            Assistentes de Modelista
                          </Typography>
                        </a>
                      </Grid>
                    </Grid>
                  </>
                )}

                {usuario.admin ||
                HasPermission('caca_costura') ||
                usuario.id_grupo === 8 ? (
                  <Grid item>
                    <Grid
                      item
                      container
                      onClick={(e) => handleClick(e, 'anchorHunt')}
                      className={classes.gridLink}
                    >
                      <Typography variant="subtitle1" color="inherit">
                        Caça-Costura
                      </Typography>
                      <ArrowDropDown />
                    </Grid>
                    <Menu
                      anchorEl={anchorHunt}
                      open={Boolean(anchorHunt)}
                      onClose={(e) => handleClose(e)}
                    >
                      {HasPermission('caca_costura') ||
                      usuario.id_grupo === 8 ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/caca-costura'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Caça-Costura
                          </a>
                        </MenuItem>
                      ) : null}
                      {usuario.admin || usuario.id_grupo === 8 ? (
                        <MenuItem>
                          <a
                            href={PLM_URL_MENU + '/admin-leilao'}
                            className={classes.navLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Admin Caça-Costura
                          </a>
                        </MenuItem>
                      ) : null}
                    </Menu>
                  </Grid>
                ) : null}
                {Configuracao()}
              </Grid>

              <Grid
                style={{
                  display: 'flex'
                }}
              >
                {Administracao()}
                {/* <Grid item>
                  <Grid
                    item
                    container
                    onClick={(e) => handleClick(e, 'anchorUser')}
                    className={classes.gridLink}
                  >
                    <Typography
                      variant="subtitle1"
                      color="inherit"
                      style={{ margin: 'auto' }}
                    >
                      {usuario.login}
                    </Typography>
                    <ArrowDropDown />
                  </Grid>
                  <Menu
                    anchorEl={anchorUser}
                    open={Boolean(anchorUser)}
                    onClose={(e) => handleClose(e)}
                  >
                    <MenuItem>
                      <span className={classes.navLink} onClick={logOut}>
                        Sair
                      </span>
                    </MenuItem>
                  </Menu>
                </Grid> */}
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      )}
    </>
  )
}

export default Navbar
