import * as S from './styles'

const Spinner = () => (
  <S.Wrapper>
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </S.Wrapper>
)

export default Spinner
