import styled, { css, DefaultTheme } from 'styled-components'
import { FieldProps } from './index'

type IconPositionProps = Pick<FieldProps, 'iconPosition'>

const wrapperModifiers = {
  borderRadius: (theme: DefaultTheme) => css`
    border-radius: 6px;
    resize: none;
    border: 1px solid ${theme.colors.black};
  `,
  bigger: () => css`
    width: 100%;
    height: 100px !important;
  `,
  AltLine: () => css`
    line-height: 50px;
    appearance: none;
  `
}

export const Icon = styled.div<IconPositionProps>`
  ${({ theme, iconPosition }) => css`
    display: flex;
    color: ${theme.colors.gray};
    order: ${iconPosition === 'right' ? 1 : 0};
    & > svg {
      width: 2.2rem;
      height: 100%;
    }
  `}
`

export const Wrapper = styled.div<FieldProps>`
  ${({ theme, TextArea, size }) => css`
    input, textarea {
      position: relative;
      height: 4.5rem;
      width: 100%;
      min-width: 100px;
      border: none;
      padding: ${theme.spacings.xsmall};
      font-weight: ${theme.font.bold};
      color: ${theme.colors.black};
      font-size: ${theme.font.sizes.medium};
      border-bottom: 1px solid ${theme.colors.darkGray};
      font-family: ${theme.font.family};
      outline: none;
      overflow-y: scroll;
      resize: none;

      ::placeholder {
        margin: 9px;
        opacity: 70%;
        font-weight: ${theme.font.bold};
        text-transform: uppercase;
        color: ${theme.colors.black};
        letter-spacing: 1px;
      }

      [type=text]:focus {
        &:focus-within
          border: none;
          border-bottom: 1px solid ${theme.colors.primary};
        }

      ${size === 'full' && wrapperModifiers.bigger()};

      ${!!TextArea && wrapperModifiers.borderRadius(theme)};
    }

    textarea { height: 140px }

    label {
      margin-bottom: 0;
      color: ${theme.colors.primary};
      font-weight: ${theme.font.bold};
      text-transform: uppercase;
      font-size: ${theme.font.sizes.small};
      ${!!TextArea && wrapperModifiers.AltLine()}
    }

    .header {
      display: flex;
      widuth: 100%;
      justify-content: space-between;
      svg {
        margin-top: 14px;
      }
    }

    span:not(.custom-arrow) {
      color: ${theme.colors.lightGray}
      font-size: ${theme.font.sizes.medium}
      font-weight: ${theme.font.bold}
      margin-top: ${theme.spacings.xsmall}
    }
  `}
`
