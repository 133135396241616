/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import Button from '../Button'
// import Button from '@mui/material/Button';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { toShowUp } from 'utils/utils'

// ******** XLSX with new header *************
const fileExtension = '.xlsx'
const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'

export type CSVExportButtonProps = {
  header: string[]
  data: any[]
  filename: string
}

const CSVExportButton = ({ data, filename }: CSVExportButtonProps) => {
  const [datacolumn, setDataColumn] = useState<any[]>([])

  useEffect(() => {
    if (!data) return

    if (data.length > 0) {
      const countersample = data.map((each) => Object.assign({}, each))

      const exportdata = countersample.map((each) => {
        Object.keys(each).map((value) => {
          if (value === 'FollowUP') {
            each[value] = decodeURIComponent(each[value])
          }
        })
        return each
      })

      const changeNamed = exportdata.map((item) => toShowUp(item))
      setDataColumn(changeNamed)
    }
  }, [data, filename])

  const exportToCSV = (csvData: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData)
    const workbook = { Sheets: { data: ws }, SheetNames: ['data'] }
    XLSX.utils.sheet_add_json(ws, csvData)
    /* generate XLSX file and send to client */
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    })
    const data = new Blob([excelBuffer], { type: fileType })

    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <>
      <Button
        color="primary"
        onClick={() => exportToCSV(datacolumn, filename)}
        style={{
          height: '3.1rem'
        }}
      >
        Exportar
      </Button>
    </>
  )
}

export default CSVExportButton

export const CSVHeader = [
  'marca',
  'linha',
  'estilista',
  'mesLoja',
  'produto',
  'descProduto',
  'statusCA',
  'provider',
  'modelista',
  'pecaNumero',
  'tempStatus',
  'talQual',
  'followUp',
  'colecao',
  'colecaoCompleta',
  'dataLacreStyle',
  'dataEntracePilot',
  'dataEntraceKit',
  'dataEntraceRisk',
  'dataSaidaFornecedor',
  'providerExit',
  'DataUltimoStatusProva',
  'dataAprovCa',
  'dataEntraceCounterSample',
  'FollowUP'
]

export const ExportNames = [
  'marca',
  'linha',
  'estilista',
  'dataprog',
  'produto',
  'descProduto',
  'statusCA',
  'fornecedor',
  'modelista',
  'pecaNumero',
  'tempoStatus',
  'talQual',
  'followUp',
  'colecao',
  'colecaoCompleta',
  'dataLacreEstilo',
  'dataEntradaPiloto',
  'dataEntradaKit',
  'dataEntradaRisco',
  'dataSaidaFornecedor',
  'fornecedorSaida',
  'DataUltimoStatusProva',
  'dataAprovCa',
  'dataEntradaContraAmostra',
  'FollowUP'
]
