/* eslint-disable prettier/prettier */
// import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import debounce from 'lodash.debounce'

import * as S from './styles'

import TextFieldNormal from '../../components/InputForms/TextFieldNormal'

// eslint-disable-next-line @typescript-eslint/ban-types
type SubMenuProps = { filterTerm: any }

// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
const SubMenu = ({ filterTerm }: SubMenuProps) => {
  // const [state, setData] = useState('')
  const { register } = useForm()

  const debouncedChange = debounce(async (value) => await onSearch(value), 1000)

  const onSearch = async (value: string) => {
    await filterTerm(value)
  }

  return (
    <S.Wrapper>
      <>
        <TextFieldNormal
          {...register('colecao')}
          label="Produto ou Descrição"
          onInputChange={(value) => debouncedChange(value)}
        />
      </>
    </S.Wrapper>
  )
}

export default SubMenu
