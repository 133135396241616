/* eslint-disable */

import useModalData from 'hooks/useModalData'
import FallBackMessage from 'components/FallbackMessage'
import Spinner from 'components/Spinner'
import Table from 'components/Table'
import { IdentifierProps, StatusLogProps } from 'types/stock'
import { useEffect, useState } from 'react'

import { Col, Modal } from 'react-bootstrap'

import TextField from '../../components/InputForms/TextField'
// import Button from 'components/Button'

type StockScreenProps = {
  identifier: IdentifierProps
}

type StatusScreenProps = {
  data: any
  onPress?: (value: any) => any
  state?: any
  hasComment: boolean
}

const OFFSET_DEFAULT = 2

export const StockScreen = ({ identifier }: StockScreenProps) => {
  const { getStocks, stock, delItem } = useModalData(
    OFFSET_DEFAULT,
    identifier.ref,
    identifier.idUser
  )

  const handleData = async () => {
    await getStocks()
  }

  useEffect(() => {
    handleData()
  }, [])

  return (
    <>
      {stock ? (
        stock.length > 0 ? (
          <Table
            headers={[
              'movimentacao',
              'origem/destino',
              'type',
              'date',
              'fornecedor'
            ]}
            headerGroups={stock}
            tableId={{
              code: identifier.ref,
              idUser: identifier.idUser
            }}
            onPress={delItem}
            hasControls={true}
          />
        ) : (
          <FallBackMessage
            topping={true}
            title="Ainda não existe dados no estoque para este produto."
          />
        )
      ) : (
        <Spinner />
      )}
    </>
  )
}

export const StatusLogScreen = ({
  data,
  onPress,
  state,
  hasComment
}: StatusScreenProps) => {
  return (
    <>
      {data ? (
        data.length > 0 ? (
          <Table
            headers={[
              'status_atual',
              'ultimo_status',
              'usuario',
              'data alteração'
            ]}
            headerGroups={data.map((v: any) => ({ buffer: '', ...v }))}
            hasControls={false}
            onPress={() => {}}
          />
        ) : (
          <FallBackMessage
            topping={false}
            title="Ainda não existe log de ca para este produto."
          />
        )
      ) : (
        <Spinner />
      )}
      <Col
        style={{
          marginTop: '30px'
        }}
        md={{ span: 10, offset: 1 }}
      >
        {hasComment ? (
          <TextField
            style={{
              overflowY: 'scroll !important',
              resize: 'none !important'
            }}
            onChange={(value) => {
              onPress && onPress(value)
            }}
            callbackLoader={state === undefined ? <Spinner /> : null}
            TextArea
            fieldName="follow up"
            initialValue={state || undefined}
            size={'full'}
          />
        ) : null}
      </Col>
    </>
  )
}
