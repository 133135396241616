import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-top: 30px;
    background-color: ${theme.colors.white};

    .checkbox_container {
      width: 420px;
      display: flex;
      margin-top: 20px;
      margin-left: 0;
      justify-content: space-between;
    }

    .col {
      .checkbox_container {
        margin-left: 3px !important;
      }
    }

    .button-container {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  `}
`
