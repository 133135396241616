import { useEffect } from 'react'
import { Grid, Typography, Paper, LinearProgress } from '@material-ui/core'
import { AxiosHttpClient } from '../resources/http'

type inativoProps = {
  type: string
  data: number[]
}

export type userInfoProps = {
  id_usuario: string
  login: string
  id_modelista: string
  admin: number
  super_user: number
  atacado: string
  email: string
  ultima_alteracao: string
  device_token: string
  compradora: string
  inativo: inativoProps
  cad_externo: string
  todos_os_encaixes: string
  id_assistente_ft: number
  id_assistente_cad: number
  assistente_pcp: string
  id_grupo: number
  id_estilista: number
  id_marca_estilo: number
  shopping_credits: string
  data_criacao: string
}

const Login = () => {
  useEffect(() => {
    const getData = async () => {
      await main()
    }

    getData()
  }, [])

  const main = async () => {
    const token = getUserToken()

    if (token) {
      const userInfo: userInfoProps | undefined = await getUserInfo(token)

      if (userInfo) {
        saveUserInfoOnLocalStorage(token, userInfo)
        redirectToMainPage()
      }
    }
  }

  const getUserToken = () => {
    const token = getUserTokenFromParams()
      ? getUserTokenFromParams()
      : getUserTokenFromLocalStorage()
    return token
  }

  const getUserTokenFromLocalStorage = () => {
    return localStorage.getItem('userToken')
  }

  const getUserInfo = async (token: string) => {
    if (!token) {
      redirectToPLM()
    }

    try {
      const response = await getUserInfoFromToken(token)

      if (response.body) return response.body
    } catch (error) {
      redirectToPLM()
    }
  }

  const getUserInfoFromToken = async (token?: string) => {
    const http = new AxiosHttpClient()

    const resp = await http.request<userInfoProps>(
      `${process.env.REACT_APP_PLM_URL}/api/login/update`,
      {
        headers: {
          authorization: token
        },
        method: 'get'
      }
    )

    return resp
  }

  const getUserTokenFromParams = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const token = urlParams.get('jwt_token')
    return token
  }

  const saveUserInfoOnLocalStorage = async (
    token: string,
    userInfo: userInfoProps
  ) => {
    localStorage.clear()
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
    localStorage.setItem('userToken', token)
  }

  const redirectToMainPage = () => {
    window.location.href = '/prod'
  }

  const redirectToPLM = () => {
    const URL: string | URL | undefined = process.env.REACT_APP_PLM_URL
    if (URL) window.location.assign(URL)
  }

  return (
    <>
      <Grid container justify="center" spacing={8}>
        <Grid item xs={6}>
          <Paper>
            <Typography variant="h6" align="center">
              {'Validando informações de login...'}
            </Typography>
            <Grid item>
              <LinearProgress />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default Login
