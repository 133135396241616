import { createGlobalStyle, css } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: 'Maven Pro';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url('../fonts/maven-pro-v22-latin-regular.eot'); /* IE9 Compat Modes */
      src: local(''),
          url('../fonts/maven-pro-v22-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/maven-pro-v22-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/maven-pro-v22-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/maven-pro-v22-latin-regular.svg#MavenPro') format('svg'); /* Legacy iOS */
    }

    @font-face {
      font-family: 'Maven Pro';
      font-style: normal;
      font-weight: 600;
      font-display: swap;
      src: url('../fonts/maven-pro-v22-latin-600.eot'); /* IE9 Compat Modes */
      src: local(''),
          url('../fonts/maven-pro-v22-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/maven-pro-v22-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/maven-pro-v22-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/maven-pro-v22-latin-600.svg#MavenPro') format('svg'); /* Legacy iOS */
    }

    @font-face {
      font-family: 'Maven Pro';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src: url('../fonts/maven-pro-v22-latin-700.eot'); /* IE9 Compat Modes */
      src: local(''),
          url('../fonts/maven-pro-v22-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../fonts/maven-pro-v22-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('../fonts/maven-pro-v22-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('../fonts/maven-pro-v22-latin-700.svg#MavenPro') format('svg'); /* Legacy iOS */
    }

    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    ${({ theme }) => css`
      html {
        font-size: 62.5%;
      }

      body {
        overflow: hidden;
        font-family: ${theme.font.family};
        a {
          text-decoration: none;
        }
      }

      #root {
        overflow: hidden;
      }
    `}
`

export default GlobalStyles
