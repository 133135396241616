import styled, { css } from 'styled-components'

export const ClearFilter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1.4em;

  a {
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  .actions {
    margin-bottom: 10px;
  }

  .modal-header {
    text-transform: uppercase;
  }

  ${({ theme }) => css`
    background-color: ${theme.colors.white};

  .mb-3 {
    cursor: pointer;
   }
  }
`}
`
