import { Children, HTMLAttributes, useMemo, useState } from 'react'
import * as S from './styles'
import { Tab } from './styles'

export interface tabProps extends HTMLAttributes<Element> {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Tabs = (props: any) => {
  const [target, setTarget] = useState<number>(0)

  const { children } = props

  const childrens: number =
    useMemo(() => Children.count(children), [children]) || 0

  function showTab(index: number) {
    if (target < childrens) {
      setTarget(index)
    }
  }

  const renderTab = () => {
    if (children![target]) {
      return children![target].props.children
    }
    return children![0].props.children
  }

  return (
    <S.Wrapper>
      <>
        <ul aria-label="tabs">
          {!Array.isArray(children) ? (
            <S.Label target={target} key={0} onClick={() => showTab}>
              {children?.props?.label}
            </S.Label>
          ) : (
            children.map((child, i) => (
              <S.Label
                key={i}
                target={target == i ? 1 : 0}
                onClick={() => showTab(i)}
              >
                {child?.props?.label}
              </S.Label>
            ))
          )}
        </ul>
      </>
      <article className="content" style={{ overflow: 'hidden' }}>
        {renderTab()}
      </article>
    </S.Wrapper>
  )
}

export { Tab }

export default Tabs
