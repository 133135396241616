import { CounterSampleProps } from 'types/counterSample'
import moment from 'moment'

export const joinDataCollection = (data: CounterSampleProps[]) => {
  const arr = data.map((item) => {
    if (!item.colecao || !item.colecaoCompleta) return

    return item.colecaoCompleta
  })

  const newFormat = [...Array.from(new Set(arr))]

  return newFormat || []
}

export const escapeString = (str?: string) => {
  if (!str) return
  const allowed =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@*_+-./,'
  str = str.toString()
  // eslint-disable-next-line prefer-const
  let len = str.length,
    R = '',
    k = 0,
    S,
    chr,
    ord
  while (k < len) {
    chr = str[k]
    if (allowed.indexOf(chr) != -1) {
      S = chr
    } else {
      ord = str.charCodeAt(k)
      if (ord < 256) {
        S = '%' + ('00' + ord.toString(16)).toUpperCase().slice(-2)
      } else {
        S = '%u' + ('0000' + ord.toString(16)).toUpperCase().slice(-4)
      }
    }
    R += S
    k++
  }
  return R
}

export const unScape = (s: string) => {
  s = ('' + s)
    .replace(/\\x3E/g, '>')
    .replace(/\\x3C/g, '<')
    .replace(/\\x22/g, '"')
    .replace(/\\x27/g, "'")
    .replace(/\\x26/g, '&')
    .replace(/\\u00A0/g, '\u00A0')
    .replace(/\\n/g, '\n')
    .replace(/\\t/g, '\t')

  return s.replace(/\\\\/g, '\\')
}

export const dataDiff = (d1: Date, d2: Date) => {
  const diff = moment(d2, 'DD/MM/YYYY HH:mm:ss').diff(
    moment(d1, 'DD/MM/YYYY HH:mm:ss')
  )

  const days = moment.duration(diff).asDays()

  return typeof days === 'number' ? Math.ceil(days) : 0
}

export const correctDate = (dataInput: string) => {
  const data = new Date(dataInput),
    dia = data.getDate().toString().padStart(2, '0'),
    mes = (data.getMonth() + 1).toString().padStart(2, '0'),
    ano = data.getFullYear()
  return `${dia}/${mes}/${ano}`
}

// export const renderWithTheme = (children: any): any =>
//   render(<ThemeProvider theme={theme}>{children}</ThemeProvider>)
