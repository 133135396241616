import { useState } from 'react'
import * as S from './styles'

export type FieldProps = {
  id?: string
  label?: string
  name?: string
  type?: string
  error?: string
  TextArea?: boolean
  icon?: React.ReactNode
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  initialValue?: string
  children?: React.ReactNode
  onInputChange?: (value: string) => void
  fieldName?: string
}

const TextFieldNormal = ({
  id,
  label,
  name,
  TextArea = false,
  fieldName,
  initialValue = '',
  disabled = false,
  icon,
  iconPosition = 'right', // eslint-disable-line
  error,
  children,
  onInputChange,
  ...props
}: FieldProps) => {
  const [value, setValue] = useState(initialValue)

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.currentTarget.value
    setValue(newValue)

    !!onInputChange && onInputChange(newValue)
  }
  return (
    <S.Wrapper>
      <input
        {...props}
        id={id}
        alt={label}
        name={name}
        placeholder={label}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </S.Wrapper>
  )
}
export default TextFieldNormal
