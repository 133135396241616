/* eslint-disable prettier/prettier */
import axios, { AxiosResponse } from 'axios'
import { HttpRequest, HttpResponse, HttpClient } from './IHttpClient'

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}/api/v1/contra-amostra`
})

export class AxiosHttpClient implements HttpClient {
  async request<Data = unknown>(
    url: string,
    data?: HttpRequest
  ): Promise<HttpResponse<Data>> {
    let axiosResponse: AxiosResponse
    try {
      axiosResponse = await api.request({
        method: data?.method || 'get',
        url: url,
        data: data?.body,
        headers: data?.headers
      })

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      axiosResponse = error.response
    }
    return {
      statusCode: axiosResponse?.status,
      body: axiosResponse?.data || []
    }
  }
}
