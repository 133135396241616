/* eslint-disable prettier/prettier */
import { useEffect, useState, useMemo } from 'react'
import * as S from './styles'
import debounce from 'lodash.debounce'
import throttle from 'lodash.throttle'
import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'

// GRID
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// COMPONENTS
import SelectField, { ChangeSelectProps } from '../../SelectField'
import TextField from '../../InputForms/TextField'
import Button from '../../Button'

import { useFetch } from '../../../lib/hooks/useFetch'
import { AxiosHttpClient } from '../../../resources/http'
import { IdentifierProps } from '../../../types/stock'
import { ProvidersProps } from '../../../types/providers'
import { GroupTypeBase } from 'react-select'
import Spinner from '../../Spinner'
import { useAgainstSample } from 'store'
import { UserInfo } from '../../TableDefault/tables'

type Options =
  | (ChangeSelectProps | GroupTypeBase<ChangeSelectProps>)[]
  | (undefined & {
      value: string | number
      label: string | number
    })

export type FormModelingProps = {
  identifier: IdentifierProps
  children?: React.ReactChild
  close: () => void
}

type TypeProps = {
  motivo: string
  id_motivo_reprovacao: number
}

const noteSend: Options = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '2',
    label: '2'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '5',
    label: '5'
  }
]

const FormSeal = ({ identifier, close }: FormModelingProps) => {
  const { register, handleSubmit } = useForm()
  const [options, setOptions] = useState<Options>([])
  const [reason, setReason] = useState<Options>([{ value: ' ', label: ' ' }])
  const [activeItemName, setActiveItemName] = useState<any[]>()
  const [userInfo, setuserInfo] = useState<UserInfo>()

  const [values, setValues] = useState({
    provider: '',
    note: '',
    reason: '',
    status: '',
    deliveryNote: null,
    noteQuality: null,
    called: '',
    idProduct: identifier.idProduct,
    idUser: identifier.idUser
  })

  const typereason = useFetch<TypeProps[]>({
    url: `/product/type-problem-seal`,
    method: 'GET',
    headers: {
      authorization: localStorage.getItem('userToken')
    }
  })

  const { clearAll }: any = useAgainstSample()

  useEffect(() => {
    if (localStorage.getItem('userInfo')) {
      const userStrings = localStorage.getItem('userInfo')
      let userInfo: UserInfo

      if (userStrings) {
        const userObject: UserInfo = JSON.parse(userStrings)

        userInfo = userObject
        setuserInfo(userInfo)
      }
    }

    const main = async () => {
      await ajax()
    }

    main()
  }, [])

  const ajax = async () => {
    const http = new AxiosHttpClient()
    const response = await http.request<any[]>(
      `/product/${identifier.idProduct}/${'lacre'}`,
      {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      }
    )

    if (response && response.body) setActiveItemName(response.body)
    if (response?.body) {
      const lacreData = {
        provider: response?.body[0].provider,
        note: response?.body[0].note,
        reason: response?.body[0].reason,
        status: response?.body[0].status,
        deliveryNote: response?.body[0].delivery_note,
        noteQuality: response.body[0].note_quality,
        called: response.body[0].called,
        idProduct: identifier.idProduct,
        idUser: identifier.idUser
      }

      setValues(lacreData)
    }
  }

  const { data } = useFetch<ProvidersProps[]>(
    {
      url: `/estoque/providers_list`,
      method: 'GET',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    },
    {
      initialData: [],
      revalidateOnFocus: false
    }
  )

  const onSubmit = () => {
    const {
      provider,
      note,
      reason,
      status,
      deliveryNote,
      noteQuality,
      idProduct,
      called
    } = values

    const request = {
      note: note
        ? note
        : activeItemName && activeItemName[0] && activeItemName[0].note
        ? activeItemName[0].note
        : null,
      code: identifier.ref
        ? identifier.ref
        : activeItemName && activeItemName[0] && activeItemName[0].code
        ? activeItemName[0].code
        : identifier.ref,
      reason: reason
        ? reason
        : activeItemName && activeItemName[0] && activeItemName[0].reason
        ? activeItemName[0].reason
        : null,
      status: status
        ? status
        : activeItemName && activeItemName[0] && activeItemName[0].status
        ? activeItemName[0].status
        : null,
      deliveryNote: deliveryNote
        ? deliveryNote
        : activeItemName && activeItemName[0] && activeItemName[0].reason
        ? activeItemName[0].delivery_note
        : 0,
      noteQuality: noteQuality
        ? noteQuality
        : activeItemName && activeItemName[0] && activeItemName[0].note_quality
        ? activeItemName[0].note_quality
        : null,
      productId: idProduct,
      provider: provider
        ? provider
        : activeItemName && activeItemName[0] && activeItemName[0].provider
        ? activeItemName[0].provider
        : null,
      called: called ? called : null,
      userId: userInfo?.id_usuario || identifier.idUser
    }

    handlModeling(request)
  }

  const handleInput = (field: string, values: any) => {
    if (field === ('provider' || 'destination')) {
      setValues((s) => ({ ...s, [field]: values }))
    } else {
      setValues((s) => ({ ...s, [field]: values }))
    }
  }

  const changeText = (value: string) => {
    const field = 'note'
    if (value) {
      setValues((s) => ({ ...s, [field]: value }))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
  const throttledEventHandler = useMemo(() => throttle(changeText, 300), [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useMemo(() => debounce(changeText, 300), [])

  const fieldNone = (data: any) => {
    const isValid =
      !data.called || !data.status || !data.provider ? false : true

    return isValid
  }

  const handlModeling = async (data: any) => {
    const method =
      activeItemName && activeItemName[0]?.called && !values.called
        ? 'patch'
        : 'post'

    const http = new AxiosHttpClient()
    const response = await http.request('/estoque/lacre', {
      body: data,
      method: method,
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    await clearAll()
    if (response && response.statusCode === 200) {
      if (method == 'patch')
        toast.success('Você acabou de deletar a data de lacre !')

      if (method == 'post') toast.success('Produto lacrado com sucesso !')

      close()
    }

    if (response && response.statusCode === 404 && fieldNone(data) === false) {
      toast.warning('Preencha os campos corretamente!')

      close()
    } else {
      if (response && response.statusCode === 500) {
        toast.error('Um problema inesperado ocorreu favor recarregar a página!')

        close()
      }
    }
  }

  useEffect(() => {
    if (data) {
      setOptions(
        data.map((option: any) => {
          return {
            value: option.provider,
            label: option.provider
          }
        })
      )
    }
  }, [data])

  useEffect(() => {
    const { data } = typereason

    if (data) {
      setReason((options) =>
        options.concat(
          data.map((option: any) => {
            return {
              value: option.motivo,
              label: option.motivo
            }
          })
        )
      )
    }
  }, [typereason.data])

  return (
    <>
      <S.Wrapper>
        <Container fluid>
          {activeItemName && activeItemName.length >= 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={{ span: 6 }}>
                  <TextField
                    {...register('called')}
                    type="date"
                    onChange={(value) => handleInput('called', value)}
                    fieldName="data"
                    initialValue={
                      activeItemName && activeItemName[0]
                        ? activeItemName[0].called
                        : null
                    }
                  />
                  <SelectField
                    {...register('status')}
                    fieldName="status"
                    onChange={(value) => handleInput('status', value)}
                    initialValue={{
                      value:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].status
                          : null,
                      label:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].status
                          : null
                    }}
                    options={[
                      { value: 'Aprovado', label: 'Aprovado' },
                      {
                        value: 'Aprovado com peça na frente',
                        label: 'Aprovado com peça na frente'
                      },
                      {
                        value: 'A aprovar com peça na frente',
                        label: 'A aprovar com peça na frente'
                      },
                      {
                        value: 'Em prova',
                        label: 'Em prova'
                      },
                      {
                        value: 'Enviar kit',
                        label: 'Enviar kit'
                      },
                      {
                        value: 'Enviar piloto',
                        label: 'Enviar piloto'
                      },
                      {
                        value: 'Enviar risco',
                        label: 'Enviar risco'
                      },
                      {
                        value: 'Enviar piloto, kit e risco',
                        label: 'Enviar piloto, kit e risco'
                      },
                      {
                        value: 'Falta kit',
                        label: 'Falta kit'
                      },
                      {
                        value: 'Falta kit e risco',
                        label: 'Falta kit e risco'
                      },
                      {
                        value: 'Falta piloto',
                        label: 'Falta piloto'
                      },
                      {
                        value: 'Falta risco',
                        label: 'Falta risco'
                      },
                      {
                        value: 'Falta piloto, kit e risco',
                        label: 'Falta piloto, kit e risco'
                      },
                      {
                        value: 'No fornecedor',
                        label: 'No fornecedor'
                      },
                      {
                        value: 'Pendente lacre',
                        label: 'Pendente lacre'
                      },
                      {
                        value: 'Verificando modelagem',
                        label: 'Verificando modelagem'
                      },
                      {
                        value: 'Aguardando piloto lacrado',
                        label: 'Aguardando piloto lacrado'
                      },
                      {
                        value: 'Reprovado',
                        label: 'Reprovado'
                      },
                      {
                        value: 'Cancelado',
                        label: 'Cancelado'
                      }
                    ]}
                  />
                  <SelectField
                    {...register('reason')}
                    fieldName="motivo"
                    onChange={(value) => handleInput('reason', value)}
                    initialValue={{
                      value:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].reason
                          : null,
                      label:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].reason
                          : null
                    }}
                    options={reason}
                  />
                  <SelectField
                    {...register('provider')}
                    fieldName="fornecedor"
                    onChange={(value) => handleInput('provider', value)}
                    initialValue={{
                      value:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].provider
                          : null,
                      label:
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].provider
                          : null
                    }}
                    options={options}
                  />
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <div className="checkbox_container">
                    <SelectField
                      {...register('deliveryNote')}
                      fieldName="nota entrega"
                      onChange={(value) => handleInput('deliveryNote', value)}
                      initialValue={{
                        value:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].delivery_note
                            : null,
                        label:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].delivery_note
                            : null
                      }}
                      options={[...noteSend]}
                    />
                    <SelectField
                      {...register('noteQuality')}
                      fieldName="nota qualidade"
                      onChange={(value) => handleInput('noteQuality', value)}
                      initialValue={{
                        value:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].note_quality
                            : null,
                        label:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].note_quality
                            : null
                      }}
                      options={[...noteSend]}
                    />
                  </div>
                  <TextField
                    {...register('note')}
                    fieldName="observação"
                    onChange={debouncedChangeHandler}
                    initialValue={
                      activeItemName && activeItemName[0]
                        ? activeItemName[0].note
                        : null
                    }
                    TextArea
                  />
                </Col>
              </Row>
              <div className="button-container">
                <Button color="primary">Salvar</Button>
              </div>
            </form>
          ) : (
            <Spinner />
          )}
        </Container>
      </S.Wrapper>
    </>
  )
}

export default FormSeal
