import { useEffect, useState } from 'react'

//events.js

function subscribe(eventName: string, listener: (event: any) => void) {
  document.addEventListener(eventName, listener)
}

function unsubscribe(eventName: string, listener: (event: any) => void) {
  document.removeEventListener(eventName, listener)
}

function publish(eventName: string, data: any) {
  const event = new CustomEvent(eventName, { detail: data })
  document.dispatchEvent(event)
}

export default function Alert() {
  const [resolve, SetResolve] = useState<(val: boolean) => void>((_val) => {
    return
  })
  const [_reject, SetReject] = useState<(err: string) => void>((_err) => {
    return
  })

  const [show, setShow] = useState(false)

  const [alertText, setText] = useState('')

  function resolveFactory(event: any) {
    return (val: boolean) => {
      setShow(false)
      setText('')
      event?.detail?.exec(val)
    }
  }

  function rejectFactory(event: any) {
    return (message: string) => {
      setShow(false)
      setText('')
      const err = new Error(message)
      event?.detail?.reject(err)
    }
  }

  function DisplayAlert(event: any) {
    event.preventDefault()

    const text = event?.detail?.text

    setText(text)
    setShow(true)

    SetResolve(() => resolveFactory(event))
    SetReject(() => rejectFactory(event))
  }

  useEffect(() => {
    subscribe('alertShow', DisplayAlert)

    return () => {
      unsubscribe('alertShow', DisplayAlert)
    }
  }, [])

  return (
    <>
      <div
        id="alert-window"
        className={`${
          show ? 'd-flex' : 'd-none'
        } top-0 w-100 position-absolute h-100 justify-content-center pt-5`}
        style={{ zIndex: '5050' }}
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <div style={{ maxWidth: '300px' }}>
            <div className="bg-danger text-light rounded-top border-bottom px-5 pt-3 pb-2">
              <h2>Alerta!</h2>
            </div>
            <div className="bg-light px-5 pb-3 pt-3 text-center">
              <h3>{alertText}</h3>
            </div>
            <div className="bg-light rounded-bottom px-5 pb-3 border-top pt-3">
              <div className="d-flex justify-content-center gap-5">
                <button
                  className="btn btn-primary font-lg px-3 py-2"
                  onClick={() => resolve(true)}
                >
                  <h4 className="mb-0">Confirmar</h4>
                </button>
                <button
                  className="btn btn-danger mb-0 px-3 py-2"
                  onClick={() => resolve(false)}
                >
                  <h4 className="mb-0">Cancelar</h4>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="alert-back"
        className={`${
          show ? 'd-flex' : 'd-none'
        } top-0 position-absolute w-100 bg-dark h-100`}
        style={{ opacity: '0.5', zIndex: '5000' }}
        onClick={() => resolve(false)}
      />
    </>
  )
}

export function display(text: string): Promise<boolean> {
  return new Promise((exec, reject) => {
    publish('alertShow', { text, exec, reject })
  })
}
