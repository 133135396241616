/* eslint-disable prettier/prettier */
import { correctDate, dataDiff } from 'utils/tests/helpers'

export function toLimit(string = '') {
  string = string.substring(0, 10)
}

export const capitalize = (str: string) => {
  if (typeof str !== 'string') {
    return ''
  }
  return str.charAt(0).toUpperCase() + str.substr(1)
}

interface IColor {
  color: string
  background: string
}

export function choiseColor(color: string, days: number) {
  const colors: IColor = { color: '', background: '' }
  colors.background

  if (days && days >= 45) colors.background = '#006666'
  if (days && days >= 31 && days < 45) colors.background = '#28a745'
  if (days && days >= 16 && days < 30) colors.background = '#E0A800'
  if (days && days >= 0 && days <= 15) colors.background = '#c82333'
  if (days && days < 0) colors.background = '#333333'

  colors.color = color == 'White' || undefined ? '#333333' : '#FFFFFF'

  return colors
}

export function toShowUp(raw: any) {
  const optionsObj: any = {}

  for (const r in raw) {
    optionsObj[r] = raw[r]
  }

  return {
    FAROL: optionsObj.bpColor,
    MARCA: optionsObj.marca,
    LINHA: optionsObj.linha,
    ESTILISTA: optionsObj.estilista,
    MODELISTA: optionsObj.modelista,
    DATA_PROG: optionsObj.dataProg,
    REFERENCIA: optionsObj.produto,
    COLECAO: optionsObj.colecaoCompleta,
    STATUS: optionsObj.statusCA,
    DATA_FORNECEDOR_SAIDA: optionsObj?.dateProviderExit
      ? correctDate(optionsObj?.dateProviderExit)
      : null,
    FORNECEDOR_SAIDA: optionsObj.providerExit || '',
    PROGRAMACAO: optionsObj.programacao,
    PECA_NUMERO: optionsObj.pecaNumero ? optionsObj.pecaNumero : 1,
    TEMPO_STATUS: optionsObj.temp_status
      ? dataDiff(new Date(optionsObj.temp_status), new Date())
      : new Date(optionsObj.dataStatusCaPa)
      ? dataDiff(new Date(optionsObj.dataStatusCaPa), new Date())
      : null,
    DESC_PRODUTO: optionsObj.descProduto,
    FORNECEDOR: optionsObj.fornecedor,
    TAL_QUAL: optionsObj.talQual ? optionsObj.talQual : 'NA',
    DATA_ALVO_CA: optionsObj.data_alvo_ca
      ? correctDate(optionsObj.data_alvo_ca)
      : '',
    DATA_ENTRADA_KIT: optionsObj.dateEntranceKitStock
      ? correctDate(optionsObj.dateEntranceKitStock)
      : '',
    DATA_ENTRADA_PILOTO: optionsObj.dateEntrancePilotStock
      ? correctDate(optionsObj.dateEntrancePilotStock)
      : '',
    DATA_ENTRADA_RISCO: optionsObj.dateEntranceRiskStock
      ? correctDate(optionsObj.dateEntranceRiskStock)
      : '',
    DATA_LACRE_ESTILO: optionsObj.dataLacreEstilo
      ? correctDate(optionsObj.dataLacreEstilo)
      : '',
    DATA_SAIDA_FORNECEDOR: optionsObj.dateProviderExit
      ? correctDate(optionsObj.dateProviderExit)
      : '',
    DATA_PREVISAO_ENTREGA_CA: optionsObj?.dateEstimatedDeliveryCa
      ? optionsObj?.dateEstimatedDeliveryCa
      : '',
    DATA_ENTREGA_CA: optionsObj?.dateDeliveryCa
      ? optionsObj?.dateDeliveryCa
      : '',
    DATA_LACRE_CA: optionsObj.called
      ? optionsObj.called?.replaceAll('-', '/')
      : optionsObj.dataAprovCa
      ? correctDate(optionsObj.dataAprovCa)
      : ''
  }
}
