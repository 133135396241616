/* eslint-disable prettier/prettier */
import { useState } from 'react'
// import * as S from './styles'
// import Select, { GroupTypeBase } from 'react-select'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'

export type ChangeSelectProps = {
  value: string
  label: string
}

interface IProps {
  label?: string
  name?: string
  initialValue?: ChangeSelectProps
  options?: ChangeSelectProps[] | undefined
  onChange?: (value: string) => void
}

const SelectFieldMui = ({
  label,
  name,
  initialValue,
  onChange,
  options
}: IProps) => {
  const [value, setValue] = useState('')

  const onInputChange = (e: any) => {
    if (e) {
      const newValue = e.target.value
      if (newValue) {
        setValue(newValue)
        !!onChange && onChange(newValue)
        value
      }
    }
  }

  return (
    <FormControl style={{ width: '125px' }}>
      <InputLabel id={label}>{name}</InputLabel>
      <Select
        label={label || 1}
        id="demo-simple-select-filled"
        value={value || initialValue?.value}
        onChange={(option) => onInputChange(option)}
      >
        {options?.map((op, i) => (
          <MenuItem key={i} value={op.label}>
            {op.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectFieldMui
