import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    padding-top: 30px;
    background-color: ${theme.colors.white};

    .checkbox_container {
      width: 350px;
      display: flex;
      margin-top: 25px;
      justify-content: space-between;

      &--modify {
        width: 150px;
      }

      label {
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #1763d5;
        color: #1763d5;
      }
    }

    .button-container {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    }
  `}
`

export const TitleForm = styled.span`
  margin-bottom: 0;
  color: #1763d5;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 26px;
`
