/* eslint-disable prettier/prettier */
import { createContext, useEffect, useMemo, useState } from 'react'
import Toolbar from '@mui/material/Toolbar'
import Grid from '@mui/material/Grid'

import { ToastContainer } from 'react-toastify'
// TEMPLATES
import ModalCounterSample from '../templates/ModalCounterSample'
import SubMenu from '../templates/subheader'

// COMPONENT
import TableDefault from '../components/TableDefault'
import Navbar from '../components/Menu'
import Resume from '../components/Resume'
import { AppBar, Autocomplete, TextField } from '@mui/material'
// Props
import { useAgainstSample } from '../store'
import { AxiosHttpClient } from 'resources/http'
import CSVExportButton, { CSVHeader } from 'components/CSVExportButton'
import Spinner from '../components/Spinner'
import InfiniteScroll from 'components/InfiniteScroll'

export const DataCountersContext: any = createContext({})

export default function Home() {
  const [collectionsFilters, setCollectionsFilters] = useState<string[]>([])

  const {
    filterCollection,
    searchByTerm,
    counterSample,
    againstSamples,
    clearAll
  }: any = useAgainstSample()
  const [dataexport, setDataExport] = useState<any[]>()

  const handleCollections = async () => {
    const ajax = async () => {
      const http = new AxiosHttpClient()

      const response = await http.request<any>(
        `estoque/filter/metadata_collection`,
        {
          body: {},
          method: 'get',
          headers: {
            authorization: localStorage.getItem('userToken')
          }
        }
      )

      if (response && response.body) {
        setCollectionsFilters(response.body.value?._value)
      }
    }

    await ajax()
  }

  useEffect(() => {
    handleCollections()

    return () => clearAll()
  }, [])

  const getDataExport = async () => {
    const ajax = async () => {
      const http = new AxiosHttpClient()

      const response = await http.request<any>(`estoque/export/data`, {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      })

      setDataExport(response.body?.ca_views?._value)
    }

    return await ajax()
  }

  useMemo(() => {
    getDataExport()
  }, [])

  const onChangeValue = (event: any, value: string) => {
    if (value) {
      event
      filterCollection(value)
    }

    if (!value) {
      filterCollection('')
    }
  }

  return (
    <>
      <Navbar />
      <ToastContainer />
      <SubMenu filterTerm={searchByTerm} />
      <DataCountersContext.Provider value={{ counters: againstSamples.state }}>
        <AppBar
          position="static"
          style={{ backgroundColor: '#fff', boxShadow: 'none' }}
        >
          <Toolbar>
            <Grid alignItems="center" container>
              <Grid item md={1}>
                <CSVExportButton
                  header={CSVHeader}
                  data={
                    againstSamples.filtered.length > 0
                      ? againstSamples.filtered
                      : dataexport && dataexport.length > 0
                      ? dataexport
                      : againstSamples.state
                  }
                  filename="contra-amostra"
                />
              </Grid>
              <Grid item md={3}>
                <Resume
                  title="Resumo"
                  resumeData={
                    counterSample.filtered > 0
                      ? againstSamples.filtered
                      : againstSamples.state
                  }
                />
              </Grid>
              <Grid item md={4}>
                <Autocomplete
                  style={{
                    width: '175px',
                    backgroundColor: '#F9F9F9',
                    marginRight: '5px',
                    marginLeft: '20px'
                  }}
                  size="small"
                  fullWidth
                  options={collectionsFilters || ['Is empty']}
                  onInputChange={onChangeValue}
                  renderInput={(params) => <TextField {...params} label={''} />}
                />
              </Grid>
              <Grid item md={4}>
                <InfiniteScroll url="" />
              </Grid>
            </Grid>
          </Toolbar>
          {againstSamples?.loading ? (
            <Spinner />
          ) : (
            <ModalCounterSample>
              {againstSamples.filtered.length > 0 ? (
                <TableDefault
                  dataTable={againstSamples.filtered.slice(0, 100)}
                />
              ) : (
                <TableDefault dataTable={againstSamples.state} />
              )}
            </ModalCounterSample>
          )}
        </AppBar>
      </DataCountersContext.Provider>
    </>
  )
}
