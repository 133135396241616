import styled, { css } from 'styled-components'
import theme from 'styles/theme'

export const Wrapper = styled.div`
  ${() => css`
    width: 100%;
    max-height: 400px;

    .resume-sintese {
      font-size: 12px;
      text-transform: uppercase;
      padding: 3px;
      margin-bottom: 0;
      cursor: pointer;
      font-weight: bolder;
      cursor pointer
    }

    .resume-header {
      font-size: 14px;
      color: ${theme.colors.black};
      background-color: '#1763D5';
      text-transform: uppercase;
      padding: 3px;
      margin-bottom: 0;
      font-weight: bold;
    }

    .resume-total {
      border-top: solid 2px;
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      padding: 3px;
      margin-bottom: 0;
    }
  `}
`
