/* eslint-disable prettier/prettier */
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import theme from './styles/theme'

import GlobalStyles from './styles/global'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AgainstSampleProvider } from './store'
import 'react-toastify/dist/ReactToastify.css'
import ErrorBoundary from './error'
import './style-bug.css'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <GlobalStyles />
      <AgainstSampleProvider>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </AgainstSampleProvider>
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
