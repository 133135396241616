import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${() => css`
    position: relative;

    h2 {
      padding-top: 40px;
      text-align: center;
      color: #1763d5;
      font-size: 18px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 1px;
    }
  `}
`
