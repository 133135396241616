import styled, { css, DefaultTheme } from 'styled-components'
import theme from '../../../styles/theme'

interface WrapperProps {
  checked: boolean
}

const wrapperModifiers = {
  checked: (theme: DefaultTheme) => css`
    transform: scale(1);
    color: ${theme.colors.primary};
  `,
  checkedColor: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary};
  `,
  focus: (theme: DefaultTheme) => css`
    color: ${theme.colors.primary};
  `
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${({ checked }) => css`
    .checkbox {
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 0.5em;
      font-size: 2rem;
      color: ${theme.colors.black};

      ${!!checked && wrapperModifiers.checkedColor(theme)}
    }

    label {
      font-size: ${theme.font.sizes.medium};
      font-weight: ${theme.font.bold};
      text-transform: uppercase;

      color: ${checked ? theme.colors.primary : theme.colors.black};
      ${checked ? wrapperModifiers.checkedColor(theme) : null}
    }

    .checkbox__input {
      display: grid;
      grid-template-areas: 'checkbox';

      > * {
        grid-area: checkbox;
      }

      input {
        opacity: 0;
        width: 1em;
        height: 1em;

        ${!!checked && wrapperModifiers.checkedColor(theme)}
      }
    }

    .checkbox__control {
      display: inline-grid;
      width: 1em;
      height: 1em;
      border-radius: 0.25em;
      border: 0.1em solid currentColor;
      cursor: pointer;

      svg {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: bottom left;
        ${checked ? wrapperModifiers.checked(theme) : null}
      }
    }
  `}
`
