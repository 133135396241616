import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/home'
import LoginValidator from './pages/login'
import Alert from 'components/ModalDeleteEstoque/Modal'

// eslint-disable-next-line react/display-name
export default () => {
  return (
    <BrowserRouter>
      <Alert />
      <Routes>
        <Route path="/" element={<LoginValidator />} />
        <Route path="prod" element={<Home />} />
      </Routes>
    </BrowserRouter>
  )
}
