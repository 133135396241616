import * as S from './styles'

import Button from '@mui/material/Button'
import MoreVert from '@mui/icons-material/MoreVert'
import CloseIcon from '@mui/icons-material/Close'
import React, { useState, useEffect } from 'react'
import { correctDate } from 'utils/tests/helpers'
import { Typography } from '@mui/material'
// import { useStyles } from './styles'
import { display } from '../ModalDeleteEstoque/Modal'
export interface CellProps {
  header: string[]
  columns: string[]
}

export type TableProps = {
  headerGroups: any
  headers: string[]
  hasControls?: boolean
  tableId?: any
  onPress: (props: any) => void
}

type DeleteItemTableProps = {
  onPress: (props: any) => void
  data: any
  id: string
}

const Table = ({ headerGroups, headers, hasControls, onPress }: TableProps) => {
  // const classes = useStyles()

  return (
    <S.Wrapper>
      <table className="table table-responsive" aria-label="Table">
        {headers.length > 0 ? (
          <thead>
            <tr>
              {hasControls && (
                <th
                  style={{
                    display: 'block',
                    paddingLeft: '44px'
                  }}
                >
                  <MoreVert />
                </th>
              )}
              {headers.map((head, i) => (
                <th key={i}>{head}</th>
              ))}
            </tr>
          </thead>
        ) : null}
        <tbody>
          {headerGroups && headerGroups.length > 0
            ? headerGroups.map((cell: any, i: number) => (
                <tr key={i} id={`${i}`}>
                  {hasControls && (
                    <td key={1}>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 600, fontSize: '1rem' }}
                      >
                        <DeleteItemTable
                          data={cell}
                          id={`${i}`}
                          onPress={onPress}
                        />
                      </Typography>
                    </td>
                  )}
                  {Object.values(cell.props || cell)
                    .slice(1)
                    .map((item: any, ui) =>
                      item && ui === 3 ? (
                        cell['date'] || cell['data_alteracao'] ? (
                          <td key={ui}>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: '1rem !important'
                              }}
                            >
                              {item ? `${correctDate(item)}` : 'N/A'}
                            </Typography>
                          </td>
                        ) : (
                          <td>
                            <Typography
                              variant="h6"
                              sx={{
                                fontWeight: 600,
                                fontSize: '1rem !important'
                              }}
                            >
                              {item ? `${item}` : 'N/A'}
                            </Typography>
                          </td>
                        )
                      ) : (
                        <td key={ui}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: 600,
                              fontSize: '1rem !important'
                            }}
                          >
                            {item ? `${item}` : 'N/A'}{' '}
                          </Typography>
                        </td>
                      )
                    )}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </S.Wrapper>
  )
}

export const DeleteItemTable = ({ data, onPress }: DeleteItemTableProps) => {
  const [idItem, setIdItem] = useState(data.id)
  const [anchorEl, _setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)

  useEffect(() => {
    setIdItem(data.id)
  }, [data])

  return (
    <>
      <div className="my-0">
        <Button
          id="basic-button"
          aria-controls={openMenu ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openMenu ? 'true' : undefined}
          onClick={async () => {
            const ret = await display(
              'Você realmente deseja excluir essa movimentação?'
            )
            if (ret) {
              onPress(idItem)
            }
          }}
        >
          <div className="my-3">
            <CloseIcon />
          </div>
        </Button>
      </div>
    </>
  )
}

export default Table
