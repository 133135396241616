import styled from 'styled-components'
import { css } from 'styled-components'

export const Wrapper = styled.div`
  ${() => css`
    label {
      color: #1763d5;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.4rem;
      line-height: 50px;
      margin-bottom: 0;
    }
  `}
`

export const SelectField = styled.div`
  .Select__control {
    height: 40px;
    width: 100%;
    border: 1px solid #a1a1a1;
    border-left: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
    border-radius: 0;
    cursor: pointer;
  }

  .Select__control:hover {
    border-color: #a1a1a1;
  }

  .Select__control--is-focused {
    box-shadow: 0 0 0 1px black;
    outline: none;
  }

  .Select__indicator-separator {
    display: none;
  }

  .Select__menu {
    color: #3c3d3e;
  }
`
