/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useReducer, useState } from 'react'

import { AxiosHttpClient } from '../../resources/http'
import { CounterSampleProps, _value } from '../../types/counterSample'

import { toast } from 'react-toastify'
import {
  againstSampleReducer,
  InitialAgainstSample
} from './againstsampleReducer'

type Response = {
  value: _value
}

// interface ResponseFilter {
//   ca_views: CounterSampleProps[]
// }

export type CounterSProps = {
  propName: string
  status?: boolean
  value: any[]
}

type CounterSampleP = {
  children: React.ReactNode
}

export const AgainstSampleContext = createContext({})

export function AgainstSampleProvider({ children }: CounterSampleP) {
  const [counters, setCounters] = useState<CounterSProps[]>([])
  // const [loading, setLoading] = useState<boolean>(false)
  const [term, setTerm] = useState<any>()

  const [againstSamples, dispatch] = useReducer(
    againstSampleReducer,
    InitialAgainstSample
  )

  const handleState = (type: string, state: any) => {
    dispatch({ type: type, payload: state })
    return
  }

  useEffect(() => {
    const fetcher = async () => {
      try {
        handleState('initialAgainstState', { loading: true })

        const http = new AxiosHttpClient()

        const response = await http.request<any>('/1', {
          body: {},
          method: 'get',
          headers: {
            authorization: localStorage.getItem('userToken')
          }
        })

        if (response && response.body) {
          const data = response?.body?.ca_views
          handleState('initialAgainstState', {
            loading: false,
            state: data || []
          })
        }
        if (!response?.body?.ca_views) {
          handleState('initialAgainstState', { loading: false, state: [] })
        }
      } catch (err) {
        handleState('initialAgainstState', { loading: false, state: [] })
        toast.error(
          'Um problema inesperado ocorreu ao tentar carregar os dados favor tente novamente!'
        )
      }
    }

    fetcher()
  }, [])

  useEffect(() => {
    const main = async () => {
      if (!term && againstSamples.state.length > 0) {
        handleState('filtered', { loading: false, filtered: [] })
        return
      }

      if (term) {
        try {
          const http = new AxiosHttpClient()

          const response = await http.request<CounterSampleProps[]>(
            `/provider/search/${1}/${term}`,
            {
              method: 'get',
              headers: {
                authorization: localStorage.getItem('userToken')
              }
            }
          )
          if (response.body) {
            const state: CounterSampleProps[] = response.body
            handleState('filtered', { loading: false, filtered: state })
          }
        } catch (error) {
          handleState('filtered', { loading: false })
        }
      }

      if (!term && againstSamples.state.length > 0)
        handleState('filtered', { loading: false })
    }

    main()
  }, [term])

  const updateStatus = (idProduct: string, status: string) => {
    const filteredState = againstSamples.filtered

    if (againstSamples.filtered.length > 0) {
      const updated = filteredState.map((filt) => {
        if (filt.produto === idProduct) {
          filt.statusCA = status
          return filt
        }
        return filt
      })

      handleState('filtered', { filtered: updated })
    }

    const updated = againstSamples.state.map((filt) => {
      if (filt.produto === idProduct) {
        filt.statusCA = status

        return filt
      } else {
        return filt
      }
    })

    handleState('updateStatus', { state: updated })
  }

  const getCounter = (counter: CounterSProps[], prop: string) => {
    const reassignment: CounterSProps[] = counters

    for (const count of counter) {
      if (
        reassignment.filter(
          (assignment: CounterSProps) => assignment.propName === count.propName
        ).length > 0
      ) {
        if (
          reassignment.filter((item: CounterSProps) =>
            item.value.includes(count.value[0])
          ).length === 0
        ) {
          const position = reassignment.findIndex((c: CounterSProps) =>
            c.propName === count.propName ? true : false
          )

          if (
            count.status &&
            reassignment[position] &&
            reassignment[position].propName === count.propName
          ) {
            reassignment[position].value.push(count.value[0])
          }
        }
      } else {
        if (
          count.status &&
          reassignment.filter((assignment) =>
            assignment.value.includes(count.value[0])
          ).length === 0
        ) {
          reassignment.push({
            propName: prop,
            status: count.status,
            value: [count.value[0]]
          })
        }
      }
    }

    setCounters(reassignment)

    if (counters.length > 0) {
      makerFilter(term)
    }
  }

  const searchByTerm = async (term: string) => {
    handleState('filtered', { loading: true })
    const http = new AxiosHttpClient()

    if (!term) {
      handleState('filtered', { loading: false, filtered: [] })
      await makerFilter(term)
      return
    }

    const response = await http.request<CounterSampleProps[]>(
      `/provider/search/${1}/${term}`,
      {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      }
    )

    if (response && response.statusCode === (500 || 404)) {
      toast.error('Um problema inesperado ocorreu favor tente novamente!')
    }

    if (response && response.body) {
      if (response.body.length === 0) {
        handleState('filtered', { loading: true })
      } else {
        const state: CounterSampleProps[] = response.body
        handleState('filtered', { loading: false, filtered: state })
      }
    }

    handleState('filtered', { loading: false })
  }

  // const searchByTerm = async (term: string) => {
  //   handleState('filtered', { loading: true })
  //   const http = new AxiosHttpClient()

  //   if (!term) {
  //     handleState('filtered', { loading: false, filtered: [] })
  //     return
  //   }

  //   // if (response && response.statusCode === (500 || 404)) {
  //   //   toast.error('Um problema inesperado ocorreu favor tente novamente!')
  //   // }

  //   try {
  //     const response = await http.request<CounterSampleProps[]>(
  //       `/provider/search/${1}/${term}`,
  //       {
  //         body: {},
  //         method: 'get',
  //         headers: {
  //           authorization: localStorage.getItem('userToken')
  //         }
  //       }
  //     )

  //     if (response && response.body) {
  //       if (response.body.length === 0) {
  //         handleState(
  //           'filtered',
  //           {
  //             filtered: [],
  //             loading: false
  //           })
  //       } else {
  //         const state: CounterSampleProps[] = response.body
  //         handleState('filtered', {
  //           filtered: state,
  //           loading: false
  //         })
  //       }
  //     }
  //   }
  //   catch (err) {
  //     handleState('filtered', {
  //       loading: false
  //     })

  //     toast.error('Um problema inesperado ocorreu favor tente novamente!')
  //   }
  // }s

  const filterCollection = async (collection?: string) => {
    handleState('filtered', { loading: true })
    if (!collection) {
      await setTerm(collection)
      await makerFilter()
      dispatch({ type: 'filtered', payload: { loading: false } })
      return
    }

    // Provide a callback to setState as second argumen
    let newStr = null
    let colecao = null
    let ano = null

    if (collection) {
      newStr = collection.split('-')
      colecao = newStr[0]
      ano = newStr[1]
    }

    let data

    if (counters.length > 0) {
      data = {
        collection: colecao,
        anoLoja: ano,
        filters: counters
      }
    }

    if (counters.length === 0) {
      data = {
        collection: colecao
      }
    }

    await setTerm(data?.collection)

    if (counters.length > 0) {
      if (data?.collection) await makerFilter(data.collection || collection)
      dispatch({ type: 'filtered', payload: { loading: false } })
      return
    }

    const http = new AxiosHttpClient()
    const response = await http.request<Response>('/estoque/filter', {
      body: data,
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response && response.statusCode === (500 || 404)) {
      toast.error('Um problema inesperado ocorreu favor tente novamente!')

      handleState('filtered', { loading: false })
    }

    if (response && response.body) {
      if (response.body?.value?._value.length === 0) {
        toast.warning('Esta pesquisa nao retornou dados tente outro termo !')

        handleState('filtered', { loading: false })
      }

      if (response && response.body) {
        const state: CounterSampleProps[] = response?.body?.value._value

        dispatch({
          type: 'filtered',
          payload: { loading: false, filtered: state }
        })
      }
    }
  }

  const makerFilter = async (filterterm?: string) => {
    if (counters.length === 0) {
      handleState('filtered', { loading: false })
      return
    }

    handleState('filtered', { loading: true })

    const http = new AxiosHttpClient()
    if (counters[0] === undefined) {
      delete counters[0]
    }

    for (const count of counters) {
      if (count.propName === 'statusCA' && count.value.includes('Aprovado')) {
        count.value.push('APROVADO')
      }
    }

    const data = {
      filters: counters,
      collection: filterterm
    }

    // Provide a callback to setState as second argument
    const response = await http.request<Response>('/estoque/filter', {
      body: data,
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response && response.statusCode === 500) {
      toast.error('Um problema inesperado ocorreu favor tente novamente!')
    }

    if (response && response.body) {
      const state: CounterSampleProps[] = response.body?.value?._value

      if (state && state.length > 0) {
        handleState('filtered', { filtered: state, loading: false })
      }
    }

    handleState('filtered', { loading: false })
  }

  const clearAll = () => {
    handleState('filtered', { filtered: [] })
    setCounters([])
  }

  const clearThisFilter = async (propName: string) => {
    setCounters((previous) =>
      previous.filter((item) => item.propName !== propName)
    )

    setTimeout(async () => await makerFilter(), 1000)
  }

  const removeOneCounter = (prop: string, item: CounterSProps) => {
    const counterSample = counters
    if (
      counterSample.length > 0 &&
      counterSample.filter((count: any) => count.propName === prop)[0] !==
        undefined &&
      counterSample
        .filter((count: any) => count.propName === prop)[0]
        .value.includes(item.value[0])
    ) {
      setCounters((prev: any) =>
        prev.map((count: any) => {
          if (count.propName === prop && count.value.includes(item.value[0])) {
            count.value.splice(count.value.indexOf(item.value[0]), 1)
            // FIZ ISSO PORQUE ESTAVA ADD DOIS ITEMS IGUAIS
            // REVER PORQUE E UMA GAMBIARRA
            if (count.propName === 'statusCA' && item.value[0] === 'Aprovado') {
              count.value.splice(count.value.indexOf('APROVADO'), 1)
            }
          }
          return count
        })
      )

      if (counters.filter((c) => c.propName === prop)[0].value.length === 0) {
        const clonecounters = counters
        setCounters(clonecounters.filter((c) => c.propName !== prop))
      }
    }
  }

  return (
    <AgainstSampleContext.Provider
      value={{
        counterSample: { counters },
        againstSamples,
        getCounter,
        clearThisFilter,
        makerFilter,
        handleState,
        removeOneCounter,
        filterCollection,
        searchByTerm,
        updateStatus,
        clearAll
      }}
    >
      {children}
    </AgainstSampleContext.Provider>
  )
}
