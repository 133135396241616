/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'

// GRID
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// COMPONENTS
import CheckBoxField from '../../InputForms/CheckField'
import TextField from '../../InputForms/TextField'
import Button from '../../Button'
import { useFetch } from '../../../lib/hooks/useFetch'

import { AxiosHttpClient } from '../../../resources/http'
import { IdentifierProps } from '../../../types/stock'
import { ProvidersProps } from '../../../types/providers'
import { GroupTypeBase } from 'react-select'
import SelectField, { ChangeSelectProps } from '../../SelectField'
import Spinner from '../../Spinner'
// import { productProps } from 'templates/ModalCounterSample'

export type FormEntraceProps = {
  identifier: IdentifierProps
  children?: React.ReactChild
  close: () => void
}

export type EntraceRequest = {
  origin: string
  note: string
  pilot: boolean
  kit: boolean
  risk: boolean
  counterSample: boolean
}

type OptionsProps = {
  pilot?: boolean
  kit?: boolean
  risk?: boolean
  counterSample?: boolean
}

export type EntraceSend = {
  origin: string
  note: string
  provider: string
  options: OptionsProps
  idProduct: string | undefined
  idUser: number | undefined
}

type Options =
  | (ChangeSelectProps | GroupTypeBase<ChangeSelectProps>)[]
  | (undefined & {
      value: string | number
      label: string | number
    })

export type EntraceState = {
  origin: string
  provider: string
  note: string
  pilot?: boolean
  kit?: boolean
  risk?: boolean
  counterSample?: boolean
  idProduct?: string
  code?: string
  idUser?: number
}

const FormEntrace = ({ identifier, close }: FormEntraceProps) => {
  const { register, handleSubmit } = useForm()
  const [options, setOptions] = useState<Options>([])
  const [loading, setLoading] = useState(false)
  const [activeItemName, setActiveItemName] = useState<any[]>()

  const [origin, setOrigin] = useState([
    { value: 'CAD', label: 'CAD' },
    { value: 'CD PAVUNA', label: 'CD PAVUNA' },
    { value: 'ESTILO', label: 'ESTILO' },
    { value: 'FORNECEDOR CA', label: 'FORNECEDOR CA' },
    { value: 'FORNECEDOR', label: 'FORNECEDOR' }
  ])

  const [values, setValues] = useState<EntraceState>({
    origin: '',
    note: '',
    pilot: undefined,
    kit: undefined,
    risk: undefined,
    provider: '',
    counterSample: undefined,
    code: identifier.ref,
    idProduct: identifier.idProduct,
    idUser: identifier.idUser || 1
  })

  useEffect(() => {
    const main = async () => {
      await ajax()
    }

    main()
  }, [])

  const ajax = async () => {
    const http = new AxiosHttpClient()

    const response = await http.request<any[]>(
      `/product/${identifier.idProduct}/${'entrada'}`,
      {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      }
    )
    if (response && response.body) {
      const newvalues = values

      setActiveItemName(response.body)
      if (response.body.length > 0) {
        setValues({
          ...newvalues,
          origin: response.body[0].origin,
          kit: response.body[0].kit === 1 ? true : false,
          risk: response.body[0].risk === 1 ? true : false,
          pilot: response.body[0].pilot === 1 ? true : false,
          counterSample: response.body[0].counter_sample === 1 ? true : false
        })
      }
    }
  }

  const { data } = useFetch<ProvidersProps[]>(
    {
      url: `/estoque/providers_list`,
      method: 'GET',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    },
    {
      revalidateOnFocus: false
    }
  )

  const onSubmit = () => {
    const {
      origin,
      provider,
      note,
      pilot,
      kit,
      risk,
      counterSample,
      code,
      idProduct,
      idUser
    } = values

    const request = {
      origin: origin
        ? origin
        : activeItemName && activeItemName[0]
        ? activeItemName[0].origin
        : null,
      note: note
        ? note
        : activeItemName && activeItemName[0]
        ? activeItemName[0].note
        : null,
      provider: provider
        ? provider
        : activeItemName && activeItemName[0]
        ? activeItemName[0].provider
        : null,
      options: {
        kit:
          kit !== null || undefined
            ? kit
            : activeItemName && activeItemName[0] && activeItemName[0].kit === 1
            ? true
            : false,
        pilot:
          pilot !== null || undefined
            ? pilot
            : activeItemName &&
              activeItemName[0] &&
              activeItemName[0].pilot === 1
            ? true
            : false,
        risk:
          risk !== null || undefined
            ? risk
            : activeItemName &&
              activeItemName &&
              activeItemName[0] &&
              activeItemName[0].risk === 1
            ? true
            : false,
        counterSample:
          counterSample !== null || undefined
            ? counterSample
            : activeItemName &&
              activeItemName[0] &&
              activeItemName[0].counterSample === 1
            ? true
            : false
      },
      code: code,
      idProduct: idProduct,
      idUser: idUser
    }

    handleEntrace(request)
  }

  const handleInput = (field: string, value: any) => {
    if (field === 'note') {
      setValues((s) => ({ ...s, [field]: value }))
    } else {
      setValues({
        ...values,
        [field]: value
      })
    }
  }

  const handleEntrace = async (data: EntraceSend) => {
    const http = new AxiosHttpClient()
    setLoading(!loading)

    const response = await http.request('/estoque/entrada', {
      body: data,
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    setLoading(!loading)

    if (response && response.statusCode === 200) {
      toast.success('Entrada adicionada com sucesso !')
      setActiveItemName([])
      close()
    }

    if (response && response.statusCode === (500 || 404)) {
      toast.error('Um problema inesperado ocorreu favor tente novamente!')
      setActiveItemName([])
      close()
    }
  }

  useEffect(() => {
    if (data) {
      const newOptions = data.map((option: any) => {
        return {
          value: option.provider,
          label: option.provider
        }
      })

      setOptions(newOptions)
    }
    setOrigin
  }, [data])

  return (
    <>
      <S.Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            {activeItemName && activeItemName.length >= 0 ? (
              <Row>
                <Col md={{ span: 6 }}>
                  <SelectField
                    {...register('origin')}
                    options={origin}
                    onChange={(value) => handleInput('origin', value)}
                    fieldName="origem"
                    initialValue={{
                      value: activeItemName[0]
                        ? activeItemName[0].origin
                        : null,
                      label: activeItemName[0] ? activeItemName[0].origin : null
                    }}
                  />
                  <SelectField
                    {...register('provider')}
                    disabled={
                      values.origin !== 'FORNECEDOR' &&
                      values.origin !== 'FORNECEDOR CA'
                    }
                    options={options}
                    onChange={(value) => handleInput('provider', value)}
                    fieldName="fornecedor"
                  />
                  <div className="checkbox_container">
                    <CheckBoxField
                      {...register('pilot')}
                      onChange={(value) => handleInput('pilot', value)}
                      fieldName="piloto"
                      initialValue={
                        activeItemName[0] && activeItemName[0].pilot === 1
                          ? true
                          : false
                      }
                    />
                    <CheckBoxField
                      {...register('kit')}
                      onChange={(value) => handleInput('kit', value)}
                      fieldName="kit"
                      initialValue={
                        activeItemName[0] && activeItemName[0].kit === 1
                          ? true
                          : false
                      }
                    />
                    <CheckBoxField
                      {...register('risk')}
                      onChange={(value) => handleInput('risk', value)}
                      fieldName="risco"
                      initialValue={
                        activeItemName[0] && activeItemName[0].risk === 1
                          ? true
                          : false
                      }
                    />
                    <CheckBoxField
                      {...register('countersample')}
                      onChange={(value) => handleInput('counterSample', value)}
                      fieldName="contra amostra"
                      initialValue={
                        activeItemName[0] &&
                        activeItemName[0].counter_sample === 1
                          ? true
                          : false
                      }
                    />
                  </div>
                </Col>
                <Col md={{ span: 5, offset: 1 }}>
                  <TextField
                    {...register('note')}
                    onChange={(value) => handleInput('note', value)}
                    TextArea
                    fieldName="observação"
                    initialValue={
                      activeItemName[0] ? activeItemName[0].note : null
                    }
                  />
                </Col>
              </Row>
            ) : (
              <Spinner />
            )}
          </Container>
          <div className="button-container">
            <Button color="primary">Salvar</Button>
          </div>
        </form>
      </S.Wrapper>
    </>
  )
}

export default FormEntrace
