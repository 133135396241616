/* eslint-disable prettier/prettier */
// import { useEffect, useState } from 'react'
import { BsFillFunnelFill } from 'react-icons/bs'

import { styled } from '@mui/material/styles'
// import { Table } from '@material-ui/core'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import theme from '../../styles/theme'

import FilterSearch from '../FilterSearch'

import { CounterSampleProps } from '../../types/counterSample'
// import { ExpandProps, ParamsProps } from '.'
// import moment from 'moment'

type HeaderTable = {
  data: CounterSampleProps[]
  accessor: string
  title: string
  size?: string
}

type AnchorProps = {
  handleModal?: () => void
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.colors.primary,
    color: theme.colors.white,
    whiteSpace: 'nowrap',
    textTransform: 'uppercase !important'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '11px !important',
    whiteSpace: 'nowrap'
  }
}))

export type UserInfo = {
  id_usuario: number
  id_marca_grupo: number
  login: string
  id_modelista: number
  admin: number
  super_user: number
  atacado: string
  email: string
  id_assistente_ft: number
  id_assistente_cad: number
}

export const StyledTableInternCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontFamily: 'Glyphicons Halflings',
    fontSize: 8,
    fontStyle: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
    fontFamily: 'Glyphicons Halflings'
  }
}))

export const ButtonShowModal = ({
  title,
  size,
  data,
  accessor
}: HeaderTable) => {
  return (
    <StyledTableCell style={{ width: size }} align="right">
      <div style={{ display: 'flex' }}>
        <FilterSearch prop={accessor} data={data}>
          <Anchor />
        </FilterSearch>
        {title}
      </div>
    </StyledTableCell>
  )
}

export const Anchor = ({ handleModal }: AnchorProps) => {
  return (
    <a
      className="anchor"
      onClick={() => {
        if (handleModal) {
          handleModal()
        }
      }}
    >
      <BsFillFunnelFill size={14}></BsFillFunnelFill>
    </a>
  )
}
