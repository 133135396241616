import React from 'react'
import * as S from './styles'

type FallBackProps = {
  title: string
  topping?: boolean
}

const FallBackMessage = ({ title, topping = true }: FallBackProps) => {
  return (
    <S.Wrapper style={{ maxWidth: topping ? '100%' : '' }}>
      <h2>{title}</h2>
    </S.Wrapper>
  )
}

export default FallBackMessage
