import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  // max-height: 500px;

  ${({ theme }) => css`
    table {
      max-width: 100%;
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      font-family: arial;
    }

    .table {
      border-bottom: #999999 solid 1px;
      width: 100%;
      margin-bottom: 20px;
    }

    .table th,
    .table td {
      font-size: 11px;
      padding: 18px 22px;
      line-height: 20px;
      text-align: left;
      vertical-align: middle;
      cursor: pointer;
    }

    table tbody tr {
      td {
        letter-spacing: 1px;
        font-size: 16px;
        font-weight: 700;
        cursor: pointer;
        text-transform: uppercase;
        max-width: 120px;
      }
    }

    .table td:last-child {
      border-right: 0;
    }

    .table thead th {
      font-weight: 700;
      background-color: #fff;
      border-right: 1px solid ${theme.colors.lightGray};
      border-top: 1px solid ${theme.colors.lightGray};
      border-bottom: 1px solid ${theme.colors.lightGray};
      color: ${theme.colors.primary};
      text-transform: uppercase;
      font-size: 12px;
      padding: 18px 22px;

      &:last-child {
        border-right: 0;
      }
    }

    /* Small Sizes */
    @media (max-width: 767px) {
      /* Responsive Table */
      .table-responsive {
        display: block;
        position: relative;
        width: 100%;
      }

      .table-responsive thead,
      .table-responsive tbody,
      .table-responsive th,
      .table-responsive td,
      .table-responsive tr {
        display: block;
      }
      .table-responsive td,
      .table-responsive th {
        height: 35px;
      }

      .table-responsive thead {
        float: left;
      }

      .table-responsive tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
      }

      .table-responsive tbody tr {
        display: inline-block;
      }

      .table td:last-child {
        display: none;
        border-right: #999999 solid 1px;
      }
    }
  `}
`

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      width: '100%'
    },
    navbarStylish: {
      boxShadow: 'none !important',
      backgroundColor: '#FAFAFA !important',
      borderBottom: `1px solid #e7e7e7`,
      height: '60px',
      color: '#777777 !important',
      MuiAppBar: {
        boxShadow: 'none !important'
      }
    },
    font: {
      fontWeight: 600,
      fontsize: '1rem',
      lineheight: 1.6,
      fontSize: `${theme.typography.subtitle1}`
    }
  })
)
