import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    max-width: 100%;
    overflow: hidden;

    .info-modelo {
      padding-left: 0px;
      overflow: scroll;
      max-height: 420px;
    }

    .body-modelo {
      padding-right: 0;
      max-height: 420px;
      overflow: hidden;
      padding-bottom: 15px;
    }

    .header-modelo {
      padding-right: 0;
      max-height: 500px;
      overflow-y: hidden;
    }

    .header-info {
      padding-left: 0;
      overflow-x: hidden;
      max-height: 500px;
      overflow-y: hidden;
    }

    .anchor {
      cursor: pointer;
    }

    table {
      background-color: transparent;
      border-collapse: collapse;
      border-spacing: 0;
      font-family: arial;
      border-bottom: #999999 solid 1px;
      width: 100%;
      margin-bottom: 0;

      th,
      td {
        font-size: ${theme.font.sizes.xsmall};
        padding: 5px;
        line-height: 14px;
        text-align: left;
        vertical-align: middle;
        cursor: pointer;

        &:last-child {
          border-right: 0;
        }
      }

      .table > :not(caption) > * > * {
        padding: 0.5px 0 !important;
      }

      thead th {
        font-weight: normal;
        background-color: ${theme.colors.primary};
        color: ${theme.colors.white};
        font-weight: ${theme.font.bold};
        font-size: ${theme.font.bold};
        /* min-width: 120px;
        max-width: 183px; */
      }

      tbody > tr:nth-child(odd) > td,
      tbody > tr:nth-child(odd) > th {
        cursor: pointer;
        background-color: #f8f8f8;
      }
    }

    .table > tbody > tr > td {
      padding: 8px;
    }

    .table > tbody > tr > td,
    .table > tbody > tr > th,
    .table > tfoot > tr > td,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > thead > tr > th {
      padding: 14px;
      vertical-align: middle;
    }
  `}
`
