/* eslint-disable prettier/prettier */
import { useEffect, useState } from 'react'
import * as S from './styles'
import { useForm } from 'react-hook-form'

import { toast } from 'react-toastify'
// GRID
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

// COMPONENTS
import { GroupTypeBase } from 'react-select'
import SelectField, { ChangeSelectProps } from '../../SelectField'
import CheckBoxField from '../../InputForms/CheckField'
import TextField from '../../InputForms/TextField'
import Button from '../../Button'

import { useFetch } from '../../../lib/hooks/useFetch'
import { AxiosHttpClient } from '../../../resources/http'
import { IdentifierProps } from '../../../types/stock'
import { ProvidersProps } from '../../../types/providers'
import Spinner from '../../Spinner'

type OptionsProps = {
  pilot?: boolean
  kit?: boolean
  risk?: boolean
  counterSample?: boolean
}

type ExitSend = {
  destination: string
  provider: string
  note: string
  options: OptionsProps
  idProduct: string | undefined
  idUser: number | undefined
}

type Options =
  | (ChangeSelectProps | GroupTypeBase<ChangeSelectProps>)[]
  | (undefined & {
      value: string | number
      label: string | number
    })

export type FormExitProps = {
  identifier: IdentifierProps
  children?: React.ReactChild
  data?: any[]
  close: () => void
}

export type ExitState = {
  destination: string
  provider: string
  note: string
  pilot?: boolean
  kit?: boolean
  risk?: boolean
  counterSample?: boolean
  idProduct?: string
  idUser?: number
}

const FormExit = ({ identifier, close }: FormExitProps) => {
  const { register, handleSubmit } = useForm()
  const [options, setOptions] = useState<Options>([])
  const [activeItemName, setActiveItemName] = useState<any[]>()

  const [destination, setDestination] = useState([
    { value: 'FORNECEDOR', label: 'FORNECEDOR' },
    { value: 'FORNECEDOR CA', label: 'FORNECEDOR CA' },
    { value: ' CD PAVUNA', label: 'CD PAVUNA' },
    { value: ' ESTILO', label: 'ESTILO' }
  ])

  const [values, setValues] = useState<ExitState>({
    destination: '',
    provider: '',
    note: '',
    pilot: undefined,
    kit: undefined,
    risk: undefined,
    counterSample: undefined,
    idProduct: identifier.idProduct,
    idUser: identifier.idUser
  })

  useEffect(() => {
    const main = async () => {
      await ajax()
    }

    main()
  }, [])

  const ajax = async () => {
    const http = new AxiosHttpClient()

    const response = await http.request<any[]>(
      `/product/${identifier.idProduct}/${'saida'}`,
      {
        body: {},
        method: 'get',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      }
    )

    if (response && response.body) {
      const newvalues = values

      setActiveItemName(response.body)
      if (response.body.length > 0) {
        setValues({
          ...newvalues,
          kit: response.body[0].kit === 1 ? true : false,
          risk: response.body[0].risk === 1 ? true : false,
          pilot: response.body[0].pilot === 1 ? true : false,
          counterSample: response.body[0].counter_sample === 1 ? true : false
        })
      }
    }
  }

  const { data } = useFetch<ProvidersProps[]>(
    {
      url: `/estoque/providers_list`,
      method: 'GET',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    },
    {
      revalidateOnFocus: false
    }
  )

  const onSubmit = () => {
    const {
      destination,
      provider,
      note,
      pilot,
      kit,
      risk,
      counterSample,
      idProduct,
      idUser
    } = values

    const request = {
      destination: destination
        ? destination
        : activeItemName && activeItemName[0]
        ? activeItemName[0].destination
        : null,
      provider: provider
        ? provider
        : activeItemName && activeItemName[0]
        ? activeItemName[0].provider
        : null,
      note: note
        ? note
        : activeItemName && activeItemName[0]
        ? activeItemName[0].note
        : null,
      options: {
        kit:
          kit !== null || undefined
            ? kit
            : activeItemName && activeItemName[0] && activeItemName[0].kit === 1
            ? true
            : false,
        pilot:
          pilot !== null || undefined
            ? pilot
            : activeItemName &&
              activeItemName[0] &&
              activeItemName[0].pilot === 1
            ? true
            : pilot,
        risk:
          risk !== null || undefined
            ? risk
            : activeItemName &&
              activeItemName[0] &&
              activeItemName[0].risk === 1
            ? true
            : false,
        counterSample:
          counterSample !== null || undefined
            ? counterSample
            : activeItemName &&
              activeItemName[0] &&
              activeItemName[0].counterSample === 1
            ? true
            : false
      },
      idProduct: idProduct,
      idUser: idUser,
      code: identifier.ref
        ? identifier.ref
        : activeItemName && activeItemName[0] && activeItemName[0].code
        ? activeItemName[0].code
        : identifier.ref
    }

    handlExit(request)
  }

  const handleInput = (field: string, values: any) => {
    if (field && values) {
      setValues((s) => ({ ...s, [field]: values }))
    }
  }

  const handlExit = async (data: ExitSend) => {
    const http = new AxiosHttpClient()

    const response = await http.request('/estoque/saida', {
      body: data,
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response && response.statusCode === 200) {
      toast.success('Saida adicionada com sucesso !')

      close()
    }

    if (response && response.statusCode === (500 || 404)) {
      toast.error('Um problema inesperado ocorreu favor recarregar a página!')

      close()
    }
  }

  useEffect(() => {
    setDestination
    if (data) {
      setOptions(
        data.map((option: any) => {
          return {
            value: option.provider,
            label: option.provider
          }
        })
      )
    }
  }, [data])

  return (
    <>
      <S.Wrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container fluid>
            {activeItemName && activeItemName.length >= 0 ? (
              <>
                <Row>
                  <Col md={{ span: 6 }}>
                    <SelectField
                      {...register('destination')}
                      fieldName="destino"
                      options={destination}
                      initialValue={{
                        value:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].destination
                            : null,
                        label:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].destination
                            : null
                      }}
                      onChange={(value) => handleInput('destination', value)}
                    />
                    <SelectField
                      {...register('provider')}
                      fieldName="fornecedor"
                      options={options}
                      initialValue={{
                        value:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].provider
                            : null,
                        label:
                          activeItemName && activeItemName[0]
                            ? activeItemName[0].provider
                            : null
                      }}
                      onChange={(value) => handleInput('provider', value)}
                    />
                    <Col>
                      <div className="checkbox_container">
                        <CheckBoxField
                          {...register('pilot')}
                          onChange={(value) => handleInput('pilot', value)}
                          fieldName="piloto"
                          initialValue={
                            activeItemName &&
                            activeItemName[0] &&
                            activeItemName[0].pilot === 1
                              ? true
                              : false
                          }
                        />
                        <CheckBoxField
                          {...register('kit')}
                          onChange={(value) => handleInput('kit', value)}
                          initialValue={
                            activeItemName &&
                            activeItemName[0] &&
                            activeItemName[0].kit === 1
                              ? true
                              : false
                          }
                          fieldName="kit"
                        />
                        <CheckBoxField
                          {...register('risk')}
                          onChange={(value) => handleInput('risk', value)}
                          initialValue={
                            activeItemName &&
                            activeItemName[0] &&
                            activeItemName[0].risk === 1
                              ? true
                              : false
                          }
                          fieldName="risco"
                        />
                      </div>
                    </Col>
                  </Col>
                  <Col md={{ span: 5, offset: 1 }}>
                    <TextField
                      {...register('note')}
                      TextArea
                      fieldName="observação"
                      initialValue={
                        activeItemName && activeItemName[0]
                          ? activeItemName[0].note
                          : null
                      }
                      onChange={(value) => handleInput('note', value)}
                    />
                  </Col>
                </Row>

                <div className="button-container">
                  <Button color="primary">Salvar</Button>
                </div>
              </>
            ) : (
              <Spinner />
            )}
          </Container>
        </form>
      </S.Wrapper>
    </>
  )
}

export default FormExit
