import { useState } from 'react'
import * as S from './styles'
import Select, { GroupTypeBase } from 'react-select'

type Options = {
  value: string
  label: string
}

export type ChangeSelectProps = {
  value: string
  label: string
}

interface IProps {
  id?: string
  label?: string
  name?: string
  type?: string
  error?: string
  icon?: React.ReactNode
  overPosition?: boolean
  isSelectorOpen?: boolean
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  initialValue?: ChangeSelectProps
  valueOptions?: Options
  options: (ChangeSelectProps | GroupTypeBase<ChangeSelectProps>)[] | undefined
  onChange?: (value: string) => void
  fieldName?: string
}

const SelectField = ({
  id,
  label,
  name,
  fieldName,
  initialValue,
  disabled = false,
  icon,
  iconPosition = 'right', // eslint-disable-line
  error,
  onChange,
  isSelectorOpen,
  overPosition = false,
  options,
  ...props
}: IProps) => {
  const [value, setValue] = useState('')

  const onInputChange = (e: any) => {
    if (e) {
      const newValue = e.value
      if (newValue) {
        setValue(newValue)
        !!onChange && onChange(newValue)
        value
      }
    }
  }

  return disabled ? null : (
    <S.Wrapper data-testid="select-input">
      {fieldName && <label htmlFor={fieldName}>{fieldName}</label>}

      {overPosition ? (
        <S.SelectField>
          <Select
            {...props}
            alt={label}
            name={name}
            menuPortalTarget={document.body}
            menuPlacement="auto"
            options={options}
            open={isSelectorOpen}
            onChange={(option) => onInputChange(option)}
            defaultValue={{
              label: initialValue?.label,
              value: initialValue?.value
            }}
          />
        </S.SelectField>
      ) : (
        <S.SelectField>
          <Select
            {...props}
            alt={label}
            name={name}
            options={options}
            open={isSelectorOpen}
            onChange={(option) => onInputChange(option)}
            defaultValue={{
              label: initialValue?.label,
              value: initialValue?.value
            }}
          />
        </S.SelectField>
      )}
    </S.Wrapper>
  )
}

export default SelectField
