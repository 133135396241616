/* eslint-disable prettier/prettier */
import {
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  useCallback
} from 'react'
import * as S from './styles'

import { ButtonShowModal } from './tables'

import { toast } from 'react-toastify'
import { AxiosHttpClient } from '../../resources/http'

import { CounterSampleProps } from '../../types/counterSample'
// PROPS
import { IdentifierProps } from '../../types/stock'

import { styled } from '@mui/material/styles'
// import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import SelectFieldMui from '../SelectField/selectFieldMui'
// import Select from '@mui/material/Select'
// import MenuItem from '@mui/material/MenuItem'

// BOOTSTRAP
import { Col, Row, Table } from 'react-bootstrap'

import theme from '../../styles/theme'
import { UserInfo } from './tables'
import { correctDate, dataDiff } from 'utils/tests/helpers'
import { useAgainstSample } from 'store'
import { TextField, Typography } from '@mui/material'
import { choiseColor } from 'utils/utils'
// import InputLabel from '@material-ui/core/InputLabel'
// import FormControl from '@material-ui/core/FormControl'

export type ParamsProps = {
  idUser?: number
  idProduct: string
  ref?: string
  note?: string
  idEstoqueCa?: string
}

export type ExpandProps = {
  dataTable?: CounterSampleProps[]
  expandComponent: React.ReactNode
  children: React.ReactNode
  key: number
}

export type TableProps = {
  dataTable: CounterSampleProps[]
  handleModal?: (options: IdentifierProps) => void
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1763D5',
    color: '#fff',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    padding: '0'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '11px !important',
    width: '100px',
    height: '61px',
    whiteSpace: 'nowrap',
    padding: '0'
  }
}))

const StyledTableInfo = styled(Table)(() => ({
  [`&.${tableCellClasses.head}`]: {},
  [`&.${tableCellClasses.body}`]: {
    tableLayout: 'fixed'
  }
}))

const StyledTableHead = styled(TableHead)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#1763D5',
    color: '#fff',
    textTransform: 'uppercase !important'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '11px !important',
    textTransform: 'uppercase !important'
  }
}))

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.colors.primary
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  }
}))

const TableDefault = ({ dataTable, handleModal }: TableProps) => {
  const [userInfo, setuserInfo] = useState<UserInfo>()
  const refBodyInfo = useRef<HTMLDivElement>(null)
  const refBodyModel = useRef<HTMLDivElement>(null)
  const refHeadInfo = useRef<HTMLDivElement>(null)
  const refHeadModel = useRef<HTMLDivElement>(null)

  const [loadingDateEstimatedDeliveryCa, setLoadingDateEstimatedDeliveryCa] =
    useState(false)

  const [loadingDateDeliveryCa, setLoadingDateDeliveryCa] = useState(false)

  const { clearAll, updateStatus }: any = useAgainstSample()
  const getuserInfos = async () => {
    let userInfo: UserInfo

    if (localStorage.getItem('userInfo')) {
      const userStrings = localStorage.getItem('userInfo')

      if (userStrings) {
        const userObject: UserInfo = JSON.parse(userStrings)

        // eslint-disable-next-line no-var
        userInfo = userObject
        setuserInfo(userInfo)
      }
    }
  }

  // The scroll listener
  const handleScroll = useCallback(() => {
    if (
      refBodyModel &&
      refBodyModel.current &&
      refBodyInfo &&
      refHeadInfo &&
      refHeadInfo.current &&
      refBodyInfo.current
    ) {
      // setLastBodyInfoTopPosition(refBodyInfo.current.scrollTop)

      refBodyModel.current.scrollTo({
        top: refBodyInfo.current.scrollTop,
        left: 0
      })

      refHeadInfo.current.scrollTo({
        left: refBodyInfo.current.scrollLeft
      })

      refBodyInfo.current.scrollTo({
        top: refBodyModel.current.scrollTop
      })
    }
  }, [])

  // Attach the scroll listener to the div
  useLayoutEffect(() => {
    if (
      refBodyInfo &&
      refBodyInfo.current &&
      refBodyModel &&
      refBodyModel.current
    ) {
      const divRefBodyInfo = refBodyInfo.current
      const divRefBodyModel = refBodyModel.current

      divRefBodyInfo.addEventListener('scroll', handleScroll)
      divRefBodyModel.addEventListener('scroll', handleScroll)

      return () => divRefBodyInfo.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    const main = async function () {
      await getuserInfos()
    }
    changeStatus
    main()
  }, [])

  const handle = (params: ParamsProps) => {
    if (params && handleModal) {
      handleModal(params)
    }
  }

  const handleChangeDateEstimatedDeliveryCa = async (
    newValue: string,
    data: any
  ) => {
    setLoadingDateEstimatedDeliveryCa(true)
    const id = data.id
    const http = new AxiosHttpClient()

    const response = await http.request('/dateEstimatedDeliveryCa', {
      body: {
        newDateEstimatedDeliveryCa: newValue,
        id: id
      },
      method: 'put'
    })

    dataTable = dataTable.map((data) => {
      if (data.id !== id) return data
      return {
        ...data,
        dateEstimatedDeliveryCa: newValue
      }
    })

    if (response && response.statusCode === 200) {
      toast.success('Data previsão de entrega CA alterado com sucesso !')
      setLoadingDateEstimatedDeliveryCa(false)

      clearAll()
    }

    if (response && response.statusCode === (500 || 404)) {
      setLoadingDateEstimatedDeliveryCa(false)

      toast.error(
        'Houve um problema para atualizar o status favor tente novamente!'
      )
    }
  }

  const handleChangeDateDeliveryCa = async (newValue: string, data: any) => {
    setLoadingDateDeliveryCa(true)
    const id = data.id
    const http = new AxiosHttpClient()

    const response = await http.request('/dateDeliveryCa', {
      body: {
        newDateDeliveryCa: newValue,
        id: id
      },
      method: 'put'
    })

    dataTable = dataTable.map((data) => {
      if (data.id !== id) return data
      return {
        ...data,
        dateDeliveryCa: newValue
      }
    })

    if (response && response.statusCode === 200) {
      toast.success('Data previsão de entrega CA alterado com sucesso !')
      setLoadingDateDeliveryCa(false)

      clearAll()
    }

    if (response && response.statusCode === (500 || 404)) {
      setLoadingDateDeliveryCa(false)

      toast.error(
        'Houve um problema para atualizar o status favor tente novamente!'
      )
    }
  }

  const changeStatus = async (
    status: string,
    idProduct: string,
    previusStatus: string
  ) => {
    const http = new AxiosHttpClient()

    const response = await http.request('/status', {
      body: {
        statusCA: status,
        idProduct: idProduct,
        previusStatus: previusStatus,
        loginUser: userInfo?.login
      },
      method: 'put'
    })

    if (response && response.statusCode === 200) {
      toast.success('Status alterado com sucesso !')
      clearAll()
      updateStatus(idProduct, status)
    }

    if (
      (response && response.statusCode === 500) ||
      (response && response.statusCode === 404)
    ) {
      toast.error(
        'Houve um problema para atualizar o status favor tente novamente!'
      )
    }
  }

  return (
    <S.Wrapper id="test">
      <TableContainer
        component={Paper}
        sx={{
          padding: 2
        }}
      >
        <Row
          style={{
            marginBottom: '0 !important',
            marginRight: '0 !important'
          }}
        >
          <Col md={{ span: 6 }} className="header-modelo" ref={refHeadModel}>
            <Table aria-label="customized table">
              <StyledTableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '28px' }}>
                    farol
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '28px' }}>
                    id
                  </StyledTableCell>
                  <ButtonShowModal
                    data={dataTable}
                    title="Marca"
                    accessor="marca"
                    size={'80px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Linha"
                    accessor="linha"
                    size={'80px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Estilista"
                    accessor="estilista"
                    size={'80px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="DATA PROG"
                    accessor="MES LOJA"
                    size={'120px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Referencia"
                    accessor="produto"
                    size={'80px'}
                  />
                </TableRow>
              </StyledTableHead>
            </Table>
          </Col>
          <Col md={{ span: 6 }} className="header-info" ref={refHeadInfo}>
            <Table
              style={{ tableLayout: 'fixed', marginBottom: '0 !important' }}
            >
              <StyledTableHead>
                <TableRow>
                  <ButtonShowModal
                    data={dataTable}
                    title="Descrição"
                    accessor="descProduto"
                    size={'180px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Status"
                    accessor="statusCA"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Fornecedor"
                    accessor="fornecedor"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Modelista"
                    accessor="modelista"
                    size={'80px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Peça Numero"
                    accessor="pecaNumero"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Tempo no Status"
                    accessor="tempStatus"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Tal Qual"
                    accessor="talQual"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Lacre Estilo"
                    accessor="dataLacre"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Entrada Piloto"
                    accessor="dataEntracePilot"
                    size={'160px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Entrada Kit"
                    accessor="dataEntraceKit"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Entrada Risco"
                    accessor="dataEntraceRisk"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Saida P Fornecedor"
                    accessor="dataSaidaFornecedor"
                    size={'180px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Fornecedor Saida"
                    accessor="providerExit"
                    size={'150px'}
                  />
                  <ButtonShowModal
                    data={dataTable}
                    title="Data Lacre CA"
                    accessor="dataAprovCa"
                    size={'180px'}
                  />
                  <StyledTableCell style={{ width: '180px' }}>
                    Previsão de entrega CA
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '180px' }}>
                    Entrega CA
                  </StyledTableCell>
                </TableRow>
              </StyledTableHead>
            </Table>
          </Col>
        </Row>
        <Row
          style={{
            marginBottom: '0 !important',
            marginRight: '0 !important'
          }}
        >
          <Col md={{ span: 6 }} ref={refBodyModel} className="body-modelo">
            <Table aria-label="customized table">
              <TableBody>
                {dataTable &&
                  dataTable?.map((data, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell style={{ width: '28px' }}>
                        <span
                          style={{
                            padding: '9px',
                            width: '32px',
                            height: '32px',
                            display: 'block',
                            fontSize: '9px',
                            textAlign: 'center',
                            // fontWeight: '600',
                            color: choiseColor(
                              data.bpColor,
                              dataDiff(new Date(), new Date(data.data_alvo_ca))
                            ).color,
                            backgroundColor: choiseColor(
                              data.bpColor,
                              dataDiff(new Date(), new Date(data.data_alvo_ca))
                            ).background,
                            borderRadius: '50%'
                          }}
                        >
                          <Typography variant="body2">
                            {dataDiff(new Date(), new Date(data.data_alvo_ca))}
                          </Typography>
                        </span>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '28px' }}>
                        {data.id}
                      </StyledTableCell>
                      <StyledTableCell
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">{data.marca}</Typography>
                      </StyledTableCell>

                      <StyledTableCell
                        style={{ width: '28px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            idEstoqueCa: data.idEstoqueCa,
                            ref: data.produto,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">{data.linha}</Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        component="th"
                        style={{ width: '100px' }}
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        {data.estilista}
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            idEstoqueCa: data.idEstoqueCa,
                            ref: data.produto,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dataProg ? correctDate(data.dataProg) : ''}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">{data.produto} </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </Col>
          <Col md={{ span: 6 }} ref={refBodyInfo} className="info-modelo">
            <StyledTableInfo
              aria-label="customized table"
              style={{ tableLayout: 'fixed' }}
            >
              <TableBody>
                {dataTable &&
                  dataTable.map((data, i: number) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell
                        style={{ width: '190px', whiteSpace: 'nowrap' }}
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data && data.descProduto
                            ? data.descProduto.slice(0, 25)
                            : ''}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          width: '150px',
                          padding: '0',
                          position: 'relative'
                        }}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        <SelectFieldMui
                          label={'statusCA'}
                          options={[
                            { value: '', label: '-' },
                            { value: 'Aprovado', label: 'Aprovado' },
                            {
                              value: 'Aprovado com peça na frente',
                              label: 'Aprovado com peça na frente'
                            },
                            {
                              value: 'A aprovar com peça na frente',
                              label: 'A aprovar com peça na frente'
                            },
                            {
                              value: 'Em prova',
                              label: 'Em prova'
                            },
                            {
                              value: 'Emitido',
                              label: 'Emitido'
                            },
                            {
                              value: 'Enviar kit',
                              label: 'Enviar kit'
                            },
                            {
                              value: 'Enviar piloto',
                              label: 'Enviar piloto'
                            },
                            {
                              value: 'Enviar risco',
                              label: 'Enviar risco'
                            },
                            {
                              value: 'Enviar piloto, kit e risco',
                              label: 'Enviar piloto, kit e risco'
                            },
                            {
                              value: 'Falta kit',
                              label: 'Falta kit'
                            },
                            {
                              value: 'Falta kit e risco',
                              label: 'Falta kit e risco'
                            },
                            {
                              value: 'Falta piloto',
                              label: 'Falta piloto'
                            },
                            {
                              value: 'Falta risco',
                              label: 'Falta risco'
                            },
                            {
                              value: 'Falta piloto, kit e risco',
                              label: 'Falta piloto, kit e risco'
                            },
                            {
                              value: 'No fornecedor',
                              label: 'No fornecedor'
                            },
                            {
                              value: 'Pendente Lacre',
                              label: 'Pendente Lacre'
                            },
                            {
                              value: 'Verificando modelagem',
                              label: 'Verificando modelagem'
                            },
                            {
                              value: 'Aguardando piloto lacrado',
                              label: 'Aguardando piloto lacrado'
                            },
                            {
                              value: 'Reprovado',
                              label: 'Reprovado'
                            },
                            {
                              value: 'Cancelado',
                              label: 'Cancelado'
                            },
                            {
                              value: 'Producao Entregue sem CA Aprovada',
                              label: 'Produção Entregue Sem CA Aprovada'
                            },
                            {
                              value: 'Aguardando Alocacao',
                              label: 'Aguardando Alocação'
                            },
                            {
                              value: 'Realocado Aguardando Devolucao',
                              label: 'Realocado Aguardando Devolução'
                            },
                            {
                              value: 'Pendente Emissao',
                              label: 'Pendente Emissão'
                            }
                          ]}
                          name={'statusCA'}
                          initialValue={{
                            label: data.statusCA,
                            value: data.statusCA
                          }}
                          onChange={(value) =>
                            changeStatus(value, data.produto, data.statusCA)
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data && data.fornecedor
                            ? data.fornecedor.slice(0, 15)
                            : ''}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data && data.modelista ? data.modelista : ''}
                        </Typography>
                      </StyledTableCell>

                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.pecaNumero ? data.pecaNumero : '1'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.tempStatus
                            ? dataDiff(new Date(data.tempStatus), new Date())
                            : new Date(data.dataStatusCaPa)
                            ? dataDiff(
                                new Date(data.dataStatusCaPa),
                                new Date()
                              )
                            : 0}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.talQual ? correctDate(data.talQual) : 'NA'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dataLacreEstilo
                            ? correctDate(data.dataLacreEstilo)
                            : '00/00/0000'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '160px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dateEntrancePilotStock
                            ? correctDate(data.dateEntrancePilotStock)
                            : data.dataEntracePilot
                            ? correctDate(data.dataEntracePilot)
                            : '00/00/0000'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dateEntranceKitStock
                            ? correctDate(data.dateEntranceKitStock)
                            : data.dataEntraceKit
                            ? correctDate(data.dataEntraceKit)
                            : '00/00/0000'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '150px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dateEntranceRiskStock
                            ? correctDate(data.dateEntranceRiskStock)
                            : data.dataEntraceRisk
                            ? correctDate(data.dataEntraceRisk)
                            : '00/00/0000'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '210px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.dateProviderExit
                            ? correctDate(data.dateProviderExit)
                            : data.dataSaidaFornecedor
                            ? correctDate(data.dataSaidaFornecedor)
                            : '00/00/0000'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '170px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.providerExit ? data.providerExit : 'NA'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '170px' }}
                        align="right"
                        component="th"
                        scope="row"
                        onClick={() =>
                          handle({
                            idUser: userInfo?.id_usuario,
                            idProduct: data.idProduct,
                            ref: data.produto,
                            idEstoqueCa: data.idEstoqueCa,
                            note: data.descProduto
                          })
                        }
                      >
                        <Typography variant="body2">
                          {data.called
                            ? data.called?.replaceAll('-', '/')
                            : data.dataAprovCa
                            ? correctDate(data.dataAprovCa)
                            : '--'}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '170px', padding: 0 }}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        <TextField
                          style={{ margin: 0 }}
                          id={`dateEstimatedDeliveryCa_` + i}
                          size="small"
                          variant="outlined"
                          margin="dense"
                          type={'date'}
                          label={
                            data.dateEstimatedDeliveryCa
                              ? 'Data previsão de entrega CA'
                              : ''
                          }
                          defaultValue={data.dateEstimatedDeliveryCa}
                          disabled={loadingDateEstimatedDeliveryCa}
                          onChange={(event) => {
                            const newValue = event.target.value
                            handleChangeDateEstimatedDeliveryCa(newValue, data)
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ width: '170px', padding: 0 }}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        <TextField
                          style={{ margin: 0 }}
                          id={`dateDeliveryCa_` + i}
                          size="small"
                          variant="outlined"
                          margin="dense"
                          type={'date'}
                          label={
                            data.dateDeliveryCa ? 'Data de entrega CA' : ''
                          }
                          defaultValue={data.dateDeliveryCa}
                          disabled={loadingDateDeliveryCa}
                          onChange={(event) => {
                            const newValue = event.target.value
                            handleChangeDateDeliveryCa(newValue, data)
                          }}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </StyledTableInfo>
          </Col>
        </Row>
      </TableContainer>
    </S.Wrapper>
  )
}

export default TableDefault
