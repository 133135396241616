/* eslint-disable prettier/prettier */
import { CounterSampleProps } from 'types/counterSample'

export interface AgainstSampleState {
  filtered: CounterSampleProps[]
  state: CounterSampleProps[]
  loading: boolean
}

export interface IAct {
  type: string
  payload: any
}

export const InitialAgainstSample = {
  filtered: [],
  state: [],
  loading: false,
  counters: []
}

export const againstSampleReducer = function (
  againstSamples: AgainstSampleState,
  action: IAct
): AgainstSampleState {
  switch (action.type) {
    case 'filtered': {
      return { ...againstSamples, ...action.payload }
    }
    case 'initialAgainstState': {
      return { ...againstSamples, ...action.payload }
    }
    case 'updateStatus': {
      return { ...againstSamples, ...action.payload }
    }
    case 'filterCounters': {
      return { ...againstSamples, ...action.payload }
    }
    default:
      throw Error('Unknown action: ' + action.type)
  }
}
