import { useContext } from 'react'
import {
  AgainstSampleProvider,
  AgainstSampleContext
} from './againstsample/against-sample'

export function useAgainstSample() {
  return useContext(AgainstSampleContext)
}

export { AgainstSampleProvider }
