/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios'
import { api } from '../../resources/http/axios-http-client/axios-http-client'

export type GetRequest = AxiosRequestConfig | null | undefined

interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'revalidate' | 'error' | 'mutate'
  > {
  data: Data | undefined
  response: AxiosResponse<Data> | undefined
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    'initialData'
  > {
  initialData?: Data
}

export function useFetch<Data = unknown, Error = unknown>(
  request: GetRequest,
  { initialData, ...config }: Config<Data, Error> = {}
): Return<Data, Error> {
  const [revalFocus, setRevalFocus] = useState<boolean | undefined>(true)
  const { revalidateOnFocus } = config

  useEffect(() => {
    if (revalidateOnFocus) setRevalFocus(revalidateOnFocus)
  }, [revalidateOnFocus])

  const {
    data: response,
    error,
    isValidating,
    revalidate,
    mutate
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    request ? JSON.stringify(request) : null,
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => api(request!),
    {
      ...config,
      revalidateOnFocus: revalFocus,
      initialData: initialData && {
        status: 200,
        statusText: 'InitialData',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        config: request!,
        headers: {},
        data: initialData
      }
    }
  )

  return {
    data: response && response.data,
    response,
    error,
    isValidating,
    revalidate,
    mutate
  }
}
