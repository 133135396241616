import styled, { css } from 'styled-components'

export const Wrapper = styled.nav`
  ${() => css`
    width: 100%;

    .title {
      text-align: center;
      width: 100%;
      font-size: 12px;
      font-weight: 600;
    }
  `}
`
