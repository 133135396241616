// import { TValues } from 'components/FilterSearch'
// import { CounterSampleProps } from 'types/counterSample'

export const formatSearchItem = (data, prop) => {
  const total = []

  for (const item of data) {
    if (total.length > 0) {
      if (
        prop &&
        !total.filter((t) => t.value.includes(item[prop])).length > 0
      ) {
        total.push({
          propName: prop,
          status: true,
          value: [item[prop]]
        })
      }
    } else {
      if (prop) {
        total.push({
          propName: prop,
          status: true,
          value: [item[prop]]
        })
      }
    }
  }

  return total
}

export const removeDuplicate = (obj) => {
  let uniqueArray = []

  for (const each of obj) {
    if (each.value[0] !== null) {
      const isTrue = uniqueArray.filter(
        (item) =>
          item.value[0]
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') ===
          each.value[0]
            .trim()
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
      ).length

      if (isTrue === 0) {
        uniqueArray.push(each)
      }
    }
  }

  return uniqueArray
}

window.like = (search) => {
  if (typeof search !== 'string' || this === null) {
    return false
  }
  // Remove special chars
  search = search.replace(
    new RegExp(
      '([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])',
      'g'
    ),
    '\\$1'
  )
  // Replace % and _ with equivalent regex
  search = search.replace(/%/g, '.*').replace(/_/g, '.')
  // Check matches
  return RegExp(`^${search}$`, 'gi').test(this)
}

export const sortFn = (search) => {
  return search.sort((a, b) =>
    a.value[0] < b.value[0] ? -1 : a.value[0] > b.value[0] ? 1 : 0
  )
}

export const filterObject = (data, counters) => {
  // eslint-disable-next-line no-undef
  const copycounter = counters
  let filtered = []

  for (const item of data) {
    copycounter.filter((newitem) => {
      const itempropname = newitem.propName
      const prop = item[itempropname]
      if (newitem.value.includes(prop)) {
        return filtered.push(item)
      }
    })
  }

  return filtered
}

export const tryAgain = (times, fn) => {
  let time = 0
  let result = null
  while (time <= times) {
    result = fn(time)

    if (result) break
    time += 1
  }
}
