/* eslint-disable prettier/prettier */
import { useState, useCallback } from 'react'
import { Stock, StockHistoric } from 'types/stock'
import { AxiosHttpClient } from 'resources/http'
import { correctDate } from 'utils/tests/helpers'
import { toast } from 'react-toastify'

type Props = {
  props: Stock
}

type Response = {
  stock: Props[]
}

interface IDeleteStock {
  code?: string
  idUser?: number
  idEstoqueCa?: string
  date?: string
}

export default function useModalData(
  offset: number,
  ref?: string,
  userId?: number
) {
  const [historic, setHistoric] = useState<StockHistoric[]>()
  const [stock, setStock] = useState<Stock[]>([])

  const mapStock = (data: any) => {
    const stocks = []

    for (const item of data) {
      stocks.push({
        id: item._id,
        movement: item.props.movement,
        origin: item.props.origin ? item.props.origin : item.props.destination,
        type: item.props.type,
        date: item.props.date,
        provider: item.props.provider
      })
    }
    const sorted = stocks.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    )
    return sorted
  }

  const mapHistoric = (data: any) => {
    const historics = []
    for (const item of data) {
      if (item.status === null || undefined) {
        item.status = 'SEM STATUS'
      }

      if (item.date) {
        if (new Date(item.date).getTime() === new Date(item.date).getTime()) {
          item.date = correctDate(item.date)
        } else {
          item.date = '00/00/0000'
        }
      }

      historics.push({
        idUser: item.idUser,
        username: item.username,
        status: item.status,
        date: item.date
      })
    }

    return historics
  }

  const getHistoric = useCallback(async () => {
    const req = new AxiosHttpClient()
    if (historic && historic.length > 0) setHistoric([])

    if (userId) {
      const response = await req.request<StockHistoric[]>(
        `/estoque/historic/${offset || 1}`,
        {
          body: { userId: Number(userId), ref, method: 'get' },
          method: 'post',
          headers: {
            authorization: localStorage.getItem('userToken')
          }
        }
      )

      if (response && response.statusCode != 500 && response.body) {
        const historics = mapHistoric(response.body)
        setHistoric(historics)
      }
    }
    return
  }, [offset, ref, userId])

  const clearAll = () => {
    setHistoric([])
    setStock([])
  }

  const clearHistoric = () => {
    setHistoric([])
  }

  const getStocks = useCallback(async () => {
    const req = new AxiosHttpClient()

    if (ref) {
      if (stock && stock.length > 0) setStock([])
      const response = await req.request<Response>(`/estoque/${offset || 1}`, {
        body: { userId, ref: ref },
        method: 'post',
        headers: {
          authorization: localStorage.getItem('userToken')
        }
      })

      if (response.statusCode != 500 && response.body) {
        const stocks = response.body.stock

        if (stocks) {
          setStock(mapStock(stocks))
        }
      }
    }

    return
  }, [offset, userId])

  const delItem = async (data: IDeleteStock) => {
    const req = new AxiosHttpClient()

    const response = await req.request<Response>(`/estoque/del/movement`, {
      body: { id: data },
      method: 'post',
      headers: {
        authorization: localStorage.getItem('userToken')
      }
    })

    if (response.statusCode === 200) {
      setStock(stock.filter((item) => item.id !== data))
      toast.success('Movimentacao deletada com sucesso!')
    }

    return response
  }

  return {
    historic,
    stock,
    getStocks,
    getHistoric,
    clearAll,
    clearHistoric,
    delItem
  }
}
